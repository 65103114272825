import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector, useDispatch } from 'react-redux'


export default function LinearDeterminate({ Color }) {
  const [progress, setProgress] = React.useState(0);
  const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)
  const LeagueLimitJoinProgress = useSelector((state) => state.Game.LeagueLimitJoinProgress)
  const JoinCountRedux = useSelector((state) => state.Game.JoinCountRedux)
  const LeagueMinimumJoinProgress = useSelector((state) => state.Game.LeagueMinimumJoinProgress)
  const xpCountLeague = useSelector((state) => state.Game.xpCountLeague)
  const LeagueWithXp = useSelector((state) => state.Game.leagueWithXp)
  const XpMin = useSelector((state) => state.Game.XpMin)
  const XpMax = useSelector((state) => state.Game.XpMax)
  // React.useEffect(() => {

  //   const timer = setInterval(() => {
  //     setProgress((oldProgress) => {
  //       if (oldProgress === 100) {
  //         return 0;
  //       }
  //       const diff = Math.random() * 10;
  //       return Math.min(oldProgress + diff, 100);
  //     });
  //   }, 2000);

  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);


  React.useEffect(() => {
  
    if (UserLeagueData.topUsers) {
      // let Count = UserLeagueData.userInformation.joinCount
      // let Count = JoinCountRedux
      let Count, min, max;
      if (LeagueWithXp == true) {
        Count = xpCountLeague
        min = XpMin
        max = XpMax
      }else if(LeagueWithXp == false){
        Count = JoinCountRedux
        min = LeagueMinimumJoinProgress
        max = LeagueLimitJoinProgress
      }

      //  let ProgressLimit= LeagueLimitJoinProgress - LeagueMinimumJoinProgress

      // let ProgressFinal = (Count * 100) / ProgressLimit
      let percentage = ((Count - min) / (max - min)) * 100

      if (Count > max) {
        setProgress(100)

      } else {
        setProgress(percentage)
      }


    }


  }, [UserLeagueData, LeagueLimitJoinProgress, JoinCountRedux])

  return (
    <Box sx={{}} className="BoxProgress">
      <LinearProgress variant="determinate" value={progress}

        sx={{
          backgroundColor: 'white',
          '& .MuiLinearProgress-bar': {
            backgroundColor: Color
            // League=="Bronze"? '#BF8970' :
            // League=="Silver"? '#8E9FAF':
            // League=="Gold"? '#FFD100':
            // League=="Platinum"? '#ACB2AC':
            // League=="Dimond"? '#B9F2FF':
            // League=="Epic"? '#8C49CA':
            // League=="Legendary"? '#C00021':
            // League=="Master"? '#5656FF':
            // League=="Grandmaster"? '#0202D3':
            // League=="Lord"? '#006C44':
            // League=="Creator"? '#212121':null
            , borderRadius: '30px'
          }
        }} style={{ 'border': '1px solid #E0E0E0', 'height': '16px', 'backgroundColor': '#F5F5F5', 'color': '#BF8970 !important', 'borderRadius': '10px' }} />
    </Box>
  );
}
