import React from 'react'
import { Link } from 'react-router-dom';
import Back from './../Public/Image/BuyCoin/Back.svg'
import './DeleteAccount.css'
import DeleteAccountImg from './../Public/Image/Profile/DeleteAccount.svg'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { useState, useEffect } from 'react';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { DeleteAccountApi } from './DeleteAccountApi';
import { GetCookie } from './../Action/GetCookie'
import { useHistory } from "react-router-dom";
import { db } from './../DataBase/db'
import { TelegramDB } from './../DataBase/TelegramDB'
import { AllApi } from './../Api/AllApi'

const DeleteAccount = () => {
    const history = useHistory();
    let Token = GetCookie('Token');

    const OpenBottomSheet = () => {
        setOpen(true)
    }
    const [open, setOpen] = useState(false)
    function onDismiss() {
        setTimeout(() => {
        setOpen(false)
    }, 300);
    }
    const DeleteAccountAccepted = async () => { 
        const MakeApiClass = new AllApi();
        let res=await MakeApiClass.DeleteAccountApi(Token)
        // let res =await DeleteAccountApi(Token)
       
        if(res.code == 200){
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
    
          
            // db.delete();
            TelegramDB.Data.clear();
            db.Data.clear();
            history.push("/");
        } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

        }
    }
    return (
        <div className='DeleteAccountContainer'>
            <div className='TransferHistoryHeader'>
                <Link to="/LogOut">
                    <img src={Back} className='TransferHistoryHeaderImg' />
                </Link>
                <div className='TransferHistoryHeaderText'>Delete account</div>
            </div>
            <div className='DeleteAccountBody'>
                <div>
                    <div className='DeleteAccountImg'>
                        <img src={DeleteAccountImg} />
                    </div>
                    <div className='DeleteAccountTxtHeader'>
                        If you delete your account, all the information associated with your Membersgram account will be lost, for example:
                    </div>
                    <div className='DeleteAccountList'>
                        <ul>
                            <li className='DeleteAccountListLI'>
                                Number of coins
                            </li>
                            <li className='DeleteAccountListLI'>
                            All accounts you join with And will be logged out
                            </li>
                            <li className='DeleteAccountListLI'>
                            List of payments
                            </li>
                            <li className='DeleteAccountListLI'>
                                List of orders
                            </li>
                            <li className='DeleteAccountListLI'>
                                Coin transfer history
                            </li>
                            {/* <li className='DeleteAccountListLI'>
                                All accounts you join with And will be logged out
                            </li> */}
                        </ul>
                    </div>


                </div>

                <div className='DeleteAccountButtons'>
                    <Link to="/LogOut" className='DeleteAccountButtonsCancel'>  Cancel </Link>
                    {/* <button className='DeleteAccountButtonsCancel'>Cancel</button> */}
                    <button className='DeleteAccountButtonsDelete' onClick={() => OpenBottomSheet()}>Delete account</button>
                </div>
            </div>
            <BottomSheet onDismiss={onDismiss} open={open}>
                <div className='RegisterBeforeBottomSheet'>
                    <div className='RegisterBeforeBottomSheetHeader'>
                        {/* <img src={ImageBottomSheet} /> */}
                        <ReportProblemOutlinedIcon style={{ "width": "24px", "height": "24px", "marginRight": "1px" }} />
                        <div className='RegisterBeforeBottomSheetHeaderText'>Delete account</div>
                    </div>
                    <div className='RegisterBeforeBottomSheetBody'>Are you sure you want to delete your account?</div>
                    <div className='RegisterBeforeBottomSheetFooter'>
                        <button className='BottomSheetCancelButton' onClick={() => setOpen(false)}>Cancel</button>
                        <button onClick={() => DeleteAccountAccepted()} className='BottomSheetLoginButton'>Delete account</button>
                    </div>
                </div>
            </BottomSheet>
        </div>
    )
}

export default DeleteAccount