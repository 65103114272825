import React from 'react'
import { useEffect } from 'react';
import { GameDB } from './../DataBase/GameDB'
import { AllApi } from './../Api/AllApi'
import { CalcTimeStartGame } from './CalcTimeStartGame'
import { GetUserDataFromAppSettingGame } from './GetUserDataFromAppSettingGame'
import { OnlineRequestAppSettingGame } from './OnlineRequestAppSettingGame'
import { OnlineRequestProfileGame } from './OnlineRequestProfileGame'
import { OnlineRequestLeagueListGame } from './OnlineRequestLeagueListGame'
import { OnlineRequestTopJoinerGame } from './OnlineRequestTopJoinerGame'
import { OnlineRequestPreviousLeague } from './OnlineRequestPreviousLeague'

import {
    SetGameLoading,
    SetAppSettingData,
    SetUserLeagueData,
    SetMyLeagueRank,
    SetLeagueLimitJoinProgress,
    SetLeagueMinimumJoinProgress,
    SetRewardLeague,
    SetMyLeagueRankNumber,
    SetGiftTopjoin,
    SetNameAccount,
    SetJoinCountRedux,
    SetPromotionBottomSheet,
    SetUserReward,
    SetClaimRewardCall,
    SetDataClaimReward,
    SetXpLimit,
    SetXpCount,SetxpCountLeague,
    SetLevelUserHeaderProgress, SetTopJoinerData, SetPrevLeagueData,
    SetSnackBarEndLeagueState, SetTopJoinerTime, SetIsBetweenLeaguge, SetProfileData
} from './../app/Game'

import { SetCongratulationsBottomSheet } from './../app/Game'
import { SetCongratulationsBottomSheet2 } from './../app/Game'
import { SetCongratulationsBottomSheet3 } from './../app/Game'
import { GetCookie } from '../Action/GetCookie'


// export async function GameStart() {
export class CheckDataBaseGame extends React.Component {
    constructor() {
        super();
        this.Token = GetCookie('Token')

    }
    //  CheckDataBase And Apis
    async CheckAppSettingData(dispatch) {
        GameDB.AppSetting.toArray(async function (AppSettingItem) {
           
            const CalcTimeStartGameClass = new CalcTimeStartGame();
            const OnlineRequestAppSettingGameGameClass = new OnlineRequestAppSettingGame();
            if (AppSettingItem.length > 0) {
             
                if (AppSettingItem[0].LastTime != false) {
                    let ResCalc = await CalcTimeStartGameClass.CalcTimeStart(AppSettingItem[0].LastTime)
                    if (ResCalc.minutes < 0 && ResCalc.seconds < 0) {
                        GameDB.AppSetting.toCollection().modify(Time => {
                            Time.LastTime = false;
                        });
                        let ResCalcOnlineRequestAppSetting = await OnlineRequestAppSettingGameGameClass.OnlineRequestAppSetting(dispatch)
                 

                    } else {
                      
                        dispatch(SetAppSettingData(AppSettingItem[0]))
                      
                    }
                } else {
                    let ResCalcOnlineRequestAppSetting = await OnlineRequestAppSettingGameGameClass.OnlineRequestAppSetting(dispatch)
                
                }
            } else {
 
                let ResCalcOnlineRequestAppSetting = await OnlineRequestAppSettingGameGameClass.OnlineRequestAppSetting(dispatch)
  

            }
        });
    }


    async CheckLeagueListData(dispatch) {
        GameDB.LeagueList.toArray(async function (LeagueListItem) {
            const CalcTimeStartGameClass = new CalcTimeStartGame();
            const OnlineRequestLeagueListGameClass = new OnlineRequestLeagueListGame();
            const GetUserDataFromAppSettingClass = new GetUserDataFromAppSettingGame();


            if (LeagueListItem.length > 0) {

                if (LeagueListItem[0].LastTime != false) {

                    let ResCalc = await CalcTimeStartGameClass.CalcTimeStart(LeagueListItem[0].LastTime)
                    // ResCalc = { minutes: 2, seconds: 1 }
                    if (ResCalc.minutes < 0 && ResCalc.seconds < 0) {
                        
                        GameDB.LeagueList.toCollection().modify(Time => {
                            Time.LastTime = false;
                        });
                        let LevelUser = false

                        let res = await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList(dispatch)
                        LevelUser = res.data.data.userInformation.leagueLevel
                    
                        let ResOfGetUserData = await GetUserDataFromAppSettingClass.GetUserDataFromAppSetting(LevelUser, dispatch)
                       
                        dispatch(SetXpCount(res.data.user.xpCount))
                        dispatch(SetJoinCountRedux(res.data.user.joinCount))
                 

                    dispatch(SetxpCountLeague(res.data.data.userInformation.xpCountLeague))

                    } else {
                        let LeagueList = await GameDB.LeagueList.toArray()

                        if (LeagueList.length > 0) {
                            // await this.OfflineRequestLeagueList(dispatch)

                            dispatch(SetUserLeagueData(LeagueList[0].LeagueList.data))
                            dispatch(SetLevelUserHeaderProgress(LeagueList[0].LeagueList))

                           

                            dispatch(SetxpCountLeague(LeagueList[0].LeagueList.data.userInformation.xpCountLeague))

                            dispatch(SetJoinCountRedux(LeagueList[0].LeagueList.user.joinCount))
                            let LevelUser = false
                            LevelUser = LeagueList[0].LeagueList.data.userInformation.leagueLevel

                            let ResOfGetUserData = await GetUserDataFromAppSettingClass.GetUserDataFromAppSetting(LevelUser, dispatch)


                        } else {

                            dispatch(SetUserLeagueData(LeagueList[0].LeagueList.data))
                            dispatch(SetLevelUserHeaderProgress(LeagueList[0].LeagueList))
                  

                            dispatch(SetxpCountLeague(LeagueList[0].LeagueList.data.userInformation.xpCountLeague))

                            dispatch(SetJoinCountRedux(LeagueList[0].LeagueList.user.joinCount))
                            let LevelUser = false
                            LevelUser = LeagueList[0].LeagueList.data.userInformation.leagueLevel

                            let ResOfGetUserData = await GetUserDataFromAppSettingClass.GetUserDataFromAppSetting(LevelUser, dispatch)



                        }
                       

                        dispatch(SetUserLeagueData(LeagueList[0].LeagueList.data))
                        dispatch(SetLevelUserHeaderProgress(LeagueList[0].LeagueList))
                     

                        dispatch(SetxpCountLeague(LeagueList[0].LeagueList.data.userInformation.xpCountLeague))

                        dispatch(SetJoinCountRedux(LeagueList[0].LeagueList.user.joinCount))
                        let LevelUser = false
                        LevelUser = LeagueList[0].LeagueList.data.userInformation.leagueLevel

                        let ResOfGetUserData = await GetUserDataFromAppSettingClass.GetUserDataFromAppSetting(LevelUser, dispatch)


                    }
                } else {



                    let LevelUser = false

                    let res = await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList(dispatch)
                    LevelUser = res.data.data.userInformation.leagueLevel
                  
                    let ResOfGetUserData = await GetUserDataFromAppSettingClass.GetUserDataFromAppSetting(LevelUser, dispatch)
                  
                    dispatch(SetxpCountLeague(res.data.data.userInformation.xpCountLeague))

                    dispatch(SetJoinCountRedux(res.data.user.joinCount))

                }
            } else {

                let LevelUser = false

                let res = await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList(dispatch)
                LevelUser = res.data.data.userInformation.leagueLevel
              

                let ResOfGetUserData = await GetUserDataFromAppSettingClass.GetUserDataFromAppSetting(LevelUser, dispatch)
           

                dispatch(SetJoinCountRedux(res.data.user.joinCount))
                // OnlineRequestLeagueList()
            }
        });
    }

    async CheckProfileData(dispatch) {
        GameDB.Profile.toArray(async function (ProfileItem) {
            const CalcTimeStartGameClass = new CalcTimeStartGame();
            const OnlineRequestProfileGameClass = new OnlineRequestProfileGame();
            if (ProfileItem.length > 0) {
                if (ProfileItem[0].LastTime != false) {
                    let ResCalc = await CalcTimeStartGameClass.CalcTimeStart(ProfileItem[0].LastTime)
                    if (ResCalc.minutes < 0 && ResCalc.seconds < 0) {
                        GameDB.Profile.toCollection().modify(Time => {
                            Time.LastTime = false;
                        });
                        await OnlineRequestProfileGameClass.OnlineRequestProfile(dispatch)
                    } else {

                        dispatch(SetProfileData(ProfileItem))
                        // OfflineRequestProfile()
                    }
                } else {
                    await OnlineRequestProfileGameClass.OnlineRequestProfile(dispatch)
                }
            } else {
                await OnlineRequestProfileGameClass.OnlineRequestProfile(dispatch)
            }
        });
    }

    async CheckTopJoiner(dispatch) {
        GameDB.TopJoiner.toArray(async function (TopJoinerItem) {
            const CalcTimeStartGameClass = new CalcTimeStartGame();
            const OnlineRequestTopJoinerGameClass = new OnlineRequestTopJoinerGame();
            if (TopJoinerItem.length > 0) {
                if (TopJoinerItem[0].LastTime != false) {
                    // let ResCalc = CalcTimeStart(TopJoinerItem[0].LastTime)
                    let ResCalc = await CalcTimeStartGameClass.CalcTimeStart(TopJoinerItem[0].LastTime)
                    if (ResCalc.minutes < 0 && ResCalc.seconds < 0) {

                        GameDB.TopJoiner.toCollection().modify(Time => {
                            Time.LastTime = false;
                        });
                        // OnlineRequestTopJoiner()
                        let ResTopJoiner = await OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner(dispatch)
                    

                        dispatch(SetJoinCountRedux(ResTopJoiner.data.user.joinCount))
                       
                        dispatch(SetxpCountLeague(ResTopJoiner.data.data.userInformation.xpCountLeague))
                    } else {
                        dispatch(SetTopJoinerData(TopJoinerItem))
                        // this.OfflineRequestTopJoiner()
                   

                        dispatch(SetJoinCountRedux(TopJoinerItem[0].userInformation.joinCount))
                        dispatch(SetxpCountLeague(TopJoinerItem[0].userInformation.xpCountLeague))


                    }
                } else {

                    let ResTopJoiner = await OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner(dispatch)
                
                    dispatch(SetJoinCountRedux(ResTopJoiner.data.user.joinCount))
            

                    dispatch(SetxpCountLeague(ResTopJoiner.data.data.userInformation.xpCountLeague))


                }
            } else {

                let ResTopJoiner = await OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner(dispatch)
             

                dispatch(SetJoinCountRedux(ResTopJoiner.data.user.joinCount))
                dispatch(SetxpCountLeague(ResTopJoiner.data.data.userInformation.xpCountLeague))


            }
        });
    }

    async CheckPreviousLeague(dispatch) {
        GameDB.PreviousLeague.toArray(async function (PreviousLeagueItem) {
            const CalcTimeStartGameClass = new CalcTimeStartGame();
            const OnlineRequestPreviousLeagueClass = new OnlineRequestPreviousLeague();
        
            if (PreviousLeagueItem.length > 0) {
                if (PreviousLeagueItem[0].LastTime != false) {
                    let ResCalc = await CalcTimeStartGameClass.CalcTimeStart(PreviousLeagueItem[0].LastTime)
                    if (true) {
                        GameDB.TopJoiner.toCollection().modify(Time => {
                            Time.LastTime = false;
                        });
                        await OnlineRequestPreviousLeagueClass.OnlineRequestPreviousLeague(dispatch)
                    } else {
                        dispatch(SetPrevLeagueData(PreviousLeagueItem))
                    }
                } else {
                    await OnlineRequestPreviousLeagueClass.OnlineRequestPreviousLeague(dispatch)
                }
            } else {
                let res = await OnlineRequestPreviousLeagueClass.OnlineRequestPreviousLeague(dispatch)
           
            }
        });
    }
    //  CheckDataBase And Apis
    //  Call Checker

    async CheckDataBase(dispatch) {
        if (GetCookie('Token')) {

            try {


            

                dispatch(SetGameLoading(true))
                this.CheckAppSettingData(dispatch)
              

                this.CheckLeagueListData(dispatch)
           

                this.CheckProfileData(dispatch)
              

                this.CheckTopJoiner(dispatch)
      

                this.CheckPreviousLeague(dispatch)
                dispatch(SetGameLoading(false))

            } catch (error) {
             
                dispatch(SetGameLoading(false))

            }
        } else {
            setTimeout(() => {
                this.CheckDataBase(dispatch)
            }, 1500);

           
        }



    }
    //  Call Checker
    async CompareJoinAndLeagueLevel(dispatch, JoinCountRedux, LeagueWithXp, XpMax, XpMin, xpCountLeague) {
        // const OnlineRequestLeagueListGameClass = new OnlineRequestLeagueListGame();
        // const OnlineRequestProfileGameClass = new OnlineRequestProfileGame();
        // const OnlineRequestTopJoinerGameClass = new OnlineRequestTopJoinerGame();
        // const OnlineRequestAppSettingGameGameClass = new OnlineRequestAppSettingGame();
        // this.OfflineRequestTopJoiner(dispatch)
        // OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner(dispatch)
        // OnlineRequestAppSettingGameGameClass.OnlineRequestAppSetting(dispatch)
        // let res = await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList(dispatch)
        // OnlineRequestProfileGameClass.OnlineRequestProfile(dispatch)
       

        let JR = JoinCountRedux
        let AppSetting = await GameDB.AppSetting.toArray()
        let Profile = await GameDB.Profile.toArray()
        let LeagueLevelProfile
        if (AppSetting.length > 0) {
            let LeaguesAppSetting = AppSetting[0].AppSetting.leagues
            // let xpCountLeague
            LeagueWithXp = true
            if (LeagueWithXp == true) {
                //  if Leauge with Xp
           
                if (AppSetting.length > 0) {
                    if (Profile.length > 0) {

                        LeagueLevelProfile = Profile[0].Profile.leagueLevel
                        // xpCountLeague=Profile[0].Profile.xpCountLeague
                    }
                    LeaguesAppSetting.map((item, i) => {
                        let min = item.minXp
                        let max = item.maxXp ? item.maxXp : false
                        let PrevIndex
                        // XpMax, XpMin
                        if (LeagueLevelProfile == item.leagueLevel) { PrevIndex = i; }
                      

                        if (min <= xpCountLeague && xpCountLeague < max) {
                        
                            if (LeagueLevelProfile == item.leagueLevel) {
                                // no need to claim reward
                           
                            } else {
                            

                                if (item.leagueLevel - LeagueLevelProfile > 0) {
                                    let Comparelevel = item.leagueLevel - LeagueLevelProfile
                                    dispatch(SetUserReward(item))
                                    let dataClaim = { prevLeague: LeagueLevelProfile, TargetLeague: item.leagueLevel, LeaguesAppSetting }
                                  
                                    dispatch(SetDataClaimReward(dataClaim))
                                    dispatch(SetPromotionBottomSheet(true))
                                }

                            }
                        } else if (min <= xpCountLeague && max == false) {
                            if (LeagueLevelProfile == item.leagueLevel) {
                                // no need to claim reward
                            } else {
                                // need to claim reward
                                if (item.leagueLevel - LeagueLevelProfile > 0) {
                                    let Comparelevel = item.leagueLevel - LeagueLevelProfile
                                    dispatch(SetUserReward(item))
                                    let dataClaim = { prevLeague: LeagueLevelProfile, TargetLeague: item.leagueLevel, LeaguesAppSetting }
                                    dispatch(SetDataClaimReward(dataClaim))
                                    dispatch(SetPromotionBottomSheet(true))
                                  

                                }

                            }
                        }
                    })
                }
            } else if (LeagueWithXp == false) {
                //  if Leauge with Join
              

                if (AppSetting.length > 0) {
                    if (Profile.length > 0) {

                        LeagueLevelProfile = Profile[0].Profile.leagueLevel
                    }

                  
                    LeaguesAppSetting.map((item, i) => {
                        let min = item.min
                        let max = item.max ? item.max : false
                        let PrevIndex
                        if (LeagueLevelProfile == item.leagueLevel) { PrevIndex = i }

                     
                        if (min <= JR && JR < max) {
                        
                            if (LeagueLevelProfile == item.leagueLevel) {
                                // no need to claim reward
                            

                              
                            } else {
                            

                                if (item.leagueLevel - LeagueLevelProfile > 0) {
                                    let Comparelevel = item.leagueLevel - LeagueLevelProfile
                                    dispatch(SetUserReward(item))
                                    let dataClaim = { prevLeague: LeagueLevelProfile, TargetLeague: item.leagueLevel, LeaguesAppSetting }
                                   
                                    dispatch(SetDataClaimReward(dataClaim))
                                    dispatch(SetPromotionBottomSheet(true))
                                }

                            }
                        } else if (min <= JR && max == false) {
                            if (LeagueLevelProfile == item.leagueLevel) {
                                // no need to claim reward
                            } else {
                                // need to claim reward
                                if (item.leagueLevel - LeagueLevelProfile > 0) {
                                    let Comparelevel = item.leagueLevel - LeagueLevelProfile
                                    dispatch(SetUserReward(item))
                                    let dataClaim = { prevLeague: LeagueLevelProfile, TargetLeague: item.leagueLevel, LeaguesAppSetting }
                                    dispatch(SetDataClaimReward(dataClaim))
                                    dispatch(SetPromotionBottomSheet(true))
                                   

                                }

                            }
                        }
                    })


                }
            } else {
              
            }
        }




    }

    // Const Style

    async CalcMiliSecond(secondsData) {
        let MiliSecondData = secondsData * 1000
        let Now = Date.now();
        let Finally = Now + MiliSecondData

        return Finally
    }
    async LoopOfGame(dispatch, intervalRef1) {
        let Status;

        let LeagueList = await GameDB.LeagueList.toArray()
        let TargetTime = localStorage.getItem('timeFinishLeague')
     
        let Now = Date.now();
        let CheckTime = (TargetTime - Now) / 1000
        if (LeagueList[0] != undefined || CheckTime != undefined) {

     

            if (CheckTime > 0) {
                CheckTime = Math.round(CheckTime)
                Status = true
                await this.CahngeLeagueStatus(dispatch, false)
                await this.IncreaseDataBaseTime(CheckTime)
                let FullTime = await this.GetFullDate(CheckTime)
            
                dispatch(SetTopJoinerTime(FullTime))
            } else {
                Status = false
              
                dispatch(SetGameLoading(true))

                await this.CahngeLeagueStatus(dispatch, true)
                let curTime = Date.now();
                let CloseSnackBarEndLeagueState = localStorage.getItem('CloseSnackBarEndLeagueState')

                // dispatch(SetSnackBarEndLeagueState(true))


                setTimeout(async () => {

                    const OnlineRequestLeagueListGameClass = new OnlineRequestLeagueListGame();
                    const OnlineRequestProfileGameClass = new OnlineRequestProfileGame();
                    const OnlineRequestTopJoinerGameClass = new OnlineRequestTopJoinerGame();
                    const OnlineRequestAppSettingGameGameClass = new OnlineRequestAppSettingGame();
                    const OnlineRequestPreviousLeagueClass = new OnlineRequestPreviousLeague();

                    await OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner(dispatch)
                    await OnlineRequestAppSettingGameGameClass.OnlineRequestAppSetting(dispatch)
                    let res = await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList(dispatch)
                    await OnlineRequestProfileGameClass.OnlineRequestProfile(dispatch)
                    let res1 = await OnlineRequestPreviousLeagueClass.OnlineRequestPreviousLeague(dispatch)

                    await this.CheckDataBase(dispatch)
                    setTimeout(() => {
                        this.OfflineRequestTopJoiner(dispatch)
                    }, 2000);

                }, 4000);

                dispatch(SetGameLoading(false))

            }

        }
        else {

            await this.CheckDataBase(dispatch)
        }
        return Status
    }
    async CahngeLeagueStatus(dispatch, status) {
        dispatch(SetIsBetweenLeaguge(status))
    }
    async IncreaseDataBaseTime(secondsData) {
        GameDB.LeagueList.toArray(function (LeagueListItem) {
            if (LeagueListItem.length > 0) {
                GameDB.LeagueList.toCollection().modify(LeagueListItem => {
                    LeagueListItem.LeagueList.data.timeFinishLeague = secondsData - 1;
                });
            }
        });
    }
    async GetFullDate(secondsData) {

        const days = Math.floor(secondsData / 86400);
        const hours = Math.floor((secondsData % 86400) / 3600);
        const minutes = Math.floor((secondsData % 3600) / 60);
        const secondsLeft = secondsData % 60;
        let m, h, s, day;
        m = String(minutes).padStart(2, "0");
        h = String(hours).padStart(2, "0");
        s = String(secondsLeft).padStart(2, "0");
        day = String(days).padStart(2, "0");
      
        return {
            days: day,
            hours: h,
            minutes: m,
            seconds: s,
        }
    }


    async CollectDataAfterClaim(dispatch) {
        const OnlineRequestLeagueListGameClass = new OnlineRequestLeagueListGame();
        const OnlineRequestProfileGameClass = new OnlineRequestProfileGame();
        const OnlineRequestTopJoinerGameClass = new OnlineRequestTopJoinerGame();
        const OnlineRequestAppSettingGameGameClass = new OnlineRequestAppSettingGame();
        await OnlineRequestAppSettingGameGameClass.OnlineRequestAppSetting(dispatch)
        await OnlineRequestLeagueListGameClass.OnlineRequestLeagueList(dispatch)
        await OnlineRequestProfileGameClass.OnlineRequestProfile(dispatch)
        await OnlineRequestTopJoinerGameClass.OnlineRequestTopJoiner(dispatch)
        this.CheckDataBase(dispatch)
    }




    async OfflineRequestTopJoiner(dispatch) {
        // setLoadingState(true)
        // dispatch(SetCongratulationsBottomSheet(true))

        let TopJoiner = await GameDB.TopJoiner.toArray()
        let PreviousLeague = await GameDB.PreviousLeague.toArray()

        // setTopjoinerState(TopJoiner[0].TopJoiner)
        // setMyUser(TopJoiner[0].userInformation)
       
        PreviousLeague[0].PreviousLeague.topUsers.map((item, i) => {

            if (item.email == PreviousLeague[0].PreviousLeague.userInformation.email) {
              

                // setIsInTop100(i + 1)

                let ShowPrevBottomSheet = localStorage.getItem('CloseCongratulations')
                let curTime = Date.now();
                
                // if (curTime + 6 < ShowPrevBottomSheet || ShowPrevBottomSheet == null) {
                // if (IsBetweenLeaguge) {
            
                if ((i + 1) == 1) {
                    
                    setTimeout(() => {
                        dispatch(SetCongratulationsBottomSheet(true))
                    }, 1000);

                } else if ((i + 1) == 2) {
                    

                    setTimeout(() => {
                        dispatch(SetCongratulationsBottomSheet2(true))
                    }, 1000);


                } else if ((i + 1) == 3) {
                  

                    setTimeout(() => {
                        dispatch(SetCongratulationsBottomSheet3(true))
                    }, 1000);


                }
                // }
                // }
            }
            // else{
            //   setIsInTop100(i+1)

            // }
        })
        // setLoadingState(false)

    }
    // async CheckClaimReward(dispatch, JoinCountRedux) {

    //     // let JR = 30
    //     // async function* generateNumbers() {
    //     //     for (let i = 29; i < 85; i++) {
    //     //       yield i;
    //     //     }
    //     //   }

    //     //   async function main() {
    //     //     for await (const number of generateNumbers()) {

    //     //         JR=number

    //     // /////////////////////////////////////////////////////////////////////////
    //     let JR = JoinCountRedux


    //     let AppSetting = await GameDB.AppSetting.toArray()
    //     let Profile = await GameDB.Profile.toArray()

    //     let LeagueLevelProfile
    //     if (Profile.length > 0) {

    //         LeagueLevelProfile = Profile[0].Profile.leagueLevel
    //     }
    //     if (AppSetting.length > 0) {

    //         let LeaguesAppSetting = AppSetting[0].AppSetting.leagues

    //         LeaguesAppSetting.map((item, i) => {
    //             let min = item.min
    //             let max = item.max ? item.max : false
    //             let PrevIndex
    //             if (LeagueLevelProfile == item.leagueLevel) { PrevIndex = i }

    //             if (min <= JR && JR < max) {

    //                 if (LeagueLevelProfile == item.leagueLevel) {
    //                     // no need to claim reward


    //                 } else {
    //                     // need to claim reward
    //                     if (item.leagueLevel - LeagueLevelProfile > 0) {
    //                         let Comparelevel = item.leagueLevel - LeagueLevelProfile

    //                         dispatch(SetUserReward(item))
    //                         let dataClaim = { prevLeague: LeagueLevelProfile, TargetLeague: item.leagueLevel, LeaguesAppSetting }

    //                         dispatch(SetDataClaimReward(dataClaim))
    //                         dispatch(SetPromotionBottomSheet(true))
    //                     }

    //                 }
    //             } else if (min <= JR && max == false) {
    //                 if (LeagueLevelProfile == item.leagueLevel) {
    //                     // no need to claim reward


    //                 } else {
    //                     // need to claim reward
    //                     if (item.leagueLevel - LeagueLevelProfile > 0) {
    //                         let Comparelevel = item.leagueLevel - LeagueLevelProfile


    //                         dispatch(SetUserReward(item))
    //                         let dataClaim = { prevLeague: LeagueLevelProfile, TargetLeague: item.leagueLevel, LeaguesAppSetting }
    //                         dispatch(SetDataClaimReward(dataClaim))
    //                         dispatch(SetPromotionBottomSheet(true))
    //                     }

    //                 }
    //             }
    //         })


    //     }
    //     // ///////////////////////////////////////////////////////////////////////////
    //     //       await new Promise(resolve => setTimeout(resolve, 1000));
    //     //     }
    //     //   }
    //     //   main()

    // }






}
