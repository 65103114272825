import React, { useState } from 'react'
import './Notice.css'
import NoticeImg from './../Public/Image/Home/Notice.svg'
import Back from './../Public/Image/Home/Back.svg'
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useEffect } from 'react';
import { setButtonStatusNotice } from './../app/Home'
import { setCheckBoxNotice } from './../app/Home'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
// import { TroubleshootOutlined } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const Notice = () => {
  const dispatch = useDispatch()
  const ButtonStatusNotice = useSelector((state) => state.Home.ButtonStatusNotice)
  // const CheckBoxNotice = useSelector((state) => state.Home.CheckBoxNotice)
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const Price = searchParams.get('Price');
  const Count = searchParams.get('Count');
  const ByCoin = searchParams.get('ByCoin');
  const _id = searchParams.get('_id');
  const sku = searchParams.get('sku');
 
 
  const HandleCheckBox = (e) => {

    if (e.target.checked == false) {
      if (TimerEnd == true) {
        dispatch(setButtonStatusNotice(false))
      }
    }
    if (e.target.checked == true) {
      if (TimerEnd == true) {
        dispatch(setButtonStatusNotice(true))
      }
    }
    dispatch(setCheckBoxNotice(e.target.checked))
  }
  const [TimerEnd, setTimerEnd] = useState(false)
  let timer1
  const timer = () => {
    var sec = 10;

    timer1 = setInterval(function () {
      if (document.getElementById('safeTimerDisplay')) {
        document.getElementById('safeTimerDisplay').innerHTML = '(' + sec + 's)';
      } else {
        clearInterval(timer1);
      }
      sec--;
      if (sec < 0) {
        clearInterval(timer1);
        if (document.getElementById('safeTimerDisplay')) {
          document.getElementById('safeTimerDisplay').innerHTML = '';
        }
        let check
        if (document.getElementById('CheckBox')) {
          check = document.getElementById('CheckBox').checked;
        }
        if (check == true) {
          
          dispatch(setButtonStatusNotice(true))
        }
        setTimerEnd(true)
      }
    }, 1000);
  }
  useEffect(() => {
    dispatch(setButtonStatusNotice(false))
    timer()
  }, [])
  const NoticeFunc = () => {

    localStorage.setItem("Notice", true)
    history.push(`/GetLinkUsername?Price=${Price}&Count=${Count}&ByCoin=${ByCoin}&_id=${_id}&sku=${sku}`);
    // ByCoin
    // _id
  }
  return (
    <div className='Notice'>
      <div className='NoticeHeader'>
        <Link to={`/Home`}>
          <img src={Back} className='NoticeHeaderImg' />
        </Link>

      </div>
      <div className='NoticeContent'>
        <img src={NoticeImg} className='NoticeContentImg' />
        <div className='NoticeContentTitle'>Member order notice</div>
        <div className='NoticeContentDes'>
          Depending on size of the order, completion of your order takes from few hours to few days
          
        </div>
        <div className='NoticeContentDes1'>
          {/* Note: Until the status of the order is "completed",Avoid doing these things. Otherwise your order will be canceled and the fee will not be refunded: */}
          Note: Until the status of the order is "completed":
        </div>
        <ul className='NoticeContentUl'>
          <li><span style={{'textDecoration':'underline'}}>Don’t</span> Change channel type or group from public to private mode</li>
          <li><span style={{'textDecoration':'underline'}}>Don’t</span>  Change the address (ID) of the channel or group</li>
        </ul>
      </div>
      <div className='NoticeFooter'>
        <div>
          <FormControlLabel className="NoticeFooterCheckbox"
            control={<Checkbox onChange={(e) => HandleCheckBox(e)} id="CheckBox" />}
            label="I agree with the explanation" />
        </div>
        <div>
          <button onClick={ButtonStatusNotice ? () => NoticeFunc() : null} className={ButtonStatusNotice ? 'NoticeFooterButton' : 'NoticeFooterButtonDisable'}>
            Got it&nbsp;<span id='safeTimerDisplay'></span>
          </button>

        </div>
      </div>
    </div>
  )
}

export default Notice