import React from 'react'
import './GameCoin.css'


import { useSelector, useDispatch } from 'react-redux'

import { AllApi } from '../../Api/AllApi'
import { GameDB } from '../../DataBase/GameDB'
import Snackbar from '@mui/material/Snackbar';
import { useState, useRef, useEffect } from 'react';


import { Link } from 'react-router-dom';
import InnerGame from './InnerGame'
import Loading from '../../Loading'
import Skeleton from '@mui/material/Skeleton';


const GameCoin = () => {
    const dispatch = useDispatch()


    const [LoadState, setLoadState] = useState(false)
    const [LastUpdate, setLastUpdate] = useState(0)
    const [NoInternet, setNoInternet] = useState(false)
  
    const intervalRef = useRef(null);

 



    return (

        !LoadState ?
            <div className='GameCoin'>
                <Link to="/Game" className='GameCoinContainer'>

                    <InnerGame />
                </Link>
               
            </div>
            : <Skeleton variant="rounded" className="SkeletonInCoinGame" width={210} height={60} />



    )
}

export default GameCoin