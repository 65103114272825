import React from 'react'
import './Buy.css'
import ic_back from './../Public/Image/BuyCoin/ic_back.svg'
import MarketerImg from './../Public/Image/BuyCoin/Marketer.svg'
import Normal from './../Public/Image/BuyCoin/Normal.svg'
import { useEffect, useState } from 'react'
// import { GetCoinBuy } from './../CoinApi/GetCoinBuy'
// import { AllApi } from './../Api/AllApi'

import { GetCookie } from './../Action/GetCookie'
import Loading from './../Loading'
import { Link } from 'react-router-dom';
import { SetEmail } from './../app/Slice'
import { useSelector, useDispatch } from 'react-redux'
import { db } from './../DataBase/db'
import { useLiveQuery } from "dexie-react-hooks";
import cryptlib from "cryptlib"
import { BottomSheet } from 'react-spring-bottom-sheet'
import VerifiedIcon from '@mui/icons-material/Verified';
import { useLocation } from 'react-router-dom';
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
// import { ChangeShop } from '../CoinApi/ChangeShop'
import { AllApi } from './../Api/AllApi'
import { useHistory } from 'react-router-dom';
import xpplus from './../Public/Image/Join/xpplus.svg'

const Buy = () => {
  let Token = GetCookie('Token');
  const dispatch = useDispatch()
  const location = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);

  const PayStatus = searchParams.get('paymentcoin');
  const CountCoin = searchParams.get('count');
  const Point = searchParams.get('point');


  const Email = useSelector((state) => state.counter.email)
  const Data = useLiveQuery(
    () => db.Data.toArray()
  );
  useEffect(() => {

    if (Data) {
    
      Data.map(item => {
        if (item.Email) { dispatch(SetEmail(item.Email)) }
      })

    }

  }, [Data])
  const [Marketer, setMarketer] = useState(false)
  const [Package, setPackage] = useState('')
  const ChangeMarket = async () => {
 
    // main/v1/changeshop
    // let res = await ChangeShop(Token)
    const MakeApiClass = new AllApi();
    let res = await MakeApiClass.ChangeShop()
 
    if (res.code == 200) {
      setPackage(res.data.data)
      GetTimePackage(res.data.data)
      setMarketer(true)
      clearInterval(timer1);

    } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
      let name = "Token"
      let name1 = "active"
      document.cookie = name + '=; Max-Age=-99999999;';
      document.cookie = name1 + '=; Max-Age=-99999999;';
      history.push("/");
      // dispatch(setLoading(false))

    }

  }
  const GetCoinPackages = async (Token) => {
 
    clearInterval(timer1);

    // let res = await GetCoinBuy(Token)

    const MakeApiClass = new AllApi();
    let res = await MakeApiClass.GetCoinBuy(false)
 
    if (res.code == 200) {
      setPackage(res.data.data)
      GetTimePackage(res.data.data)

    } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
      let name = "Token"
      let name1 = "active"
      document.cookie = name + '=; Max-Age=-99999999;';
      document.cookie = name1 + '=; Max-Age=-99999999;';
      history.push("/");
      // dispatch(setLoading(false))

    } else if (res == "err in GetCoinBuy : AxiosError: Network Error") {
      setPackage([])

    }
    setMarketer(false)

  }
  const GetTimePackage = async (Package1) => {
    Package1.map(item => {
      if (item.remain_time) {
        timer(item.remain_time)
      }
    })
  }
  useEffect(() => {
    GetCoinPackages(Token)
    if (PayStatus == 'true') {
      setSuccessB(true)
  

      searchParams.delete('paymentcoin');
      searchParams.delete('count');
      searchParams.delete('point');

      const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState({ path: newUrl }, '', newUrl);

    } else if (PayStatus == 'false') {
      setUnSuccessB(true)
      searchParams.delete('paymentcoin');
      searchParams.delete('count');
      searchParams.delete('point');
      const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState({ path: newUrl }, '', newUrl);

    }
  }, [PayStatus]);
  const [Hour, setHour] = useState(0)
  const [Minute, setMinute] = useState(0)
  const [Second, setSecond] = useState(0)


  function msToTime(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    m = String(m).padStart(2, "0");
    h = String(h).padStart(2, "0");
    s = String(s).padStart(2, "0");

    setHour(h)
    setMinute(m)
    setSecond(s)
  }
  let timer1
  const timer = (ms) => {
    var sec = Math.floor(ms / 1000);

    timer1 = setInterval(function () {
      sec--;
      if (sec < 0) {
        clearInterval(timer1);
        GetCoinPackages(Token)

      } else {
        msToTime(sec)
        return sec
      }

    }, 1000);
    return sec
  }
  const [LoadingState, setLoadingState] = useState(false)
  const Pay = async (item) => {


    setLoadingState(true)

    const iv = '#GV^%^SUR&T*#Y*4'
    const key = cryptlib.getHashSha256('#GV^%^SUR&T*#Y*4', 32);
    // _id=${item._id}&sku=${item.sku}&phonenumber=${989303741690}&email=${'majidpnx@gmail.com'
    const user = {
      "email": Email,
      "_id": item._id, // بسته _id
      "sku": item.sku,
      "price": item.price,
      // "username": UserNameForOrder,
    }
    let data = cryptlib.encrypt(JSON.stringify(user), key, iv);

    window.location.href = `https://api.membersgram.com/main/v1/webapp/purchasecoinpaypal?data=${data}`
    setLoadingState(false)


  }

  const [SuccessB, setSuccessB] = useState(false)
  const [UnSuccessB, setUnSuccessB] = useState(false)
  function onDismiss() {
    setTimeout(() => {
      RestartAfterUnSuccess()
    
    }, 300);
  }
  const RestartAfterUnSuccess=()=>{
    window.location.reload();
    setSuccessB(false)
  }

  

  return (
    <div className='BuyCoin'>
      <div className='BuyCoinContainer'>
        {

          LoadingState ?
            <Loading />
            :

            Package != '' ?


              Package.length > 0 ?
                Package.map((item, i) =>
                  item.type == 'first' || item.type == "special" ?
                    <div onClick={() => Pay(item)} style={{ "backgroundColor": `${item.background}` }} className='SpecialBuyCoinItem' key={i}>

                      <div className='SpecialBuyCoinItem1'>
                        <div>
                          <img className="SpecialBuyCoinItemImg" src={item.image} />
                        </div>
                        <div>
                          <div className='SpecialBuyCoinItem1Count' style={{ "color": `${item.textColor}` }}>{item.coincount}</div>
                          <div className='SpecialBuyCoinItem1CountText' style={{ "color": `${item.textColor}` }}>{item.onvan}</div>
                        </div>
                      </div>

                      <div className='SpecialBuyCoinItem2'>
                        <div className='SpecialBuyCoinItem2Time'>
                          {
                            // item.remain_time
                          }
                          <span style={{ "color": `${item.textColor}` }}>{Hour}</span>
                          :
                          <span style={{ "color": `${item.textColor}` }}>
                            {Minute}
                          </span>
                          :
                          <span style={{ "color": `${item.textColor}` }}>
                            {Second}
                          </span>
                        </div>
                        <div>
                          <button className='SpecialBuyCoinItem2Button' style={{ "backgroundColor": `${item.background}`, "color": `${item.textColor}` }}>
                            $ {item.price}
                          </button>
                        </div>
                        {
                          item.discount ?
                            <div className='SpecialBuyCoinItem2Offer' style={{ "color": `${item.textColor}` }}>

                              {(item.price / (1 - item.discount / 100)).toFixed(2)}


                            </div>
                            : null
                        }

                      </div>
                    </div>
                    : null
                ) : 'length'
              : <Loading />
        }
        {
          Package != '' ?


            Package.length > 0 ?
              Package.map((item, i) =>
                item.type == 'normal' ?
                  <div onClick={() => Pay(item)}
                    className='BuyCoinItem' key={i}>
                    <div className='BuyCoinItem1'>
                      <div>
                        <img className='BuyCoinItem1Img' src={item.image} />
                      </div>
                      <div className='BuyCoinCount'>{item.coincount.toLocaleString()}</div>
                      {item.xpCount ?<span className='XpCountInlist'>+ {item.xpCount} <img src={xpplus} className='XpCountInlistIcon' /> </span>: null}  

                    </div>
                    <div className='BuyCoinItem2'>
                      {item.onvan ?
                        <div className='BuyCoinOFF'>{item.onvan}</div>
                        : null
                      }
                      <div>
                      
                        <button className='BuyCoinButtonPrice'>$ {item.price}</button>
                        {item.onvan ?
                          <div className='OffPriceUnderButton'>
                            {/* {item.price/1-item.discount} */}
                           {item.market =='zarinpal' ? null:'$'} {(item.price / (1 - item.discount / 100)).toFixed(2)

                            }  {item.market =='zarinpal' ? 'T':null}

                          </div>
                          : null}
                      </div>
                    </div>
                  </div>

                  : null
              ) : 'length'
            : <Loading />





        }
        {
          // Marketer ?
          // <div className='ChangePackage' onClick={() => GetCoinPackages()}>
          //   <div className='ChangePackageLogoContainer'>
          //     <img className='ChangePackageLogo' src={Normal} />

          //     <div className='ChangePackageTexts'>
          //       <div className='ChangePackageText1'>Need smaller packages?</div>
          //       <div className='ChangePackageText2'>Entering the normal store</div>
          //     </div>
          //   </div>

          //   <div className="ChangePackageimg">
          //     <img src={ic_back} />


          //   </div>
          // </div>
          // :
          // <div className='ChangePackage' onClick={() => ChangeMarket()}>
          //   <div className='ChangePackageLogoContainer'>
          //     <img className='ChangePackageLogo' src={MarketerImg} />

          //     <div className='ChangePackageTexts'>
          //       <div className='ChangePackageText1'>Big package with special discount</div>
          //       <div className='ChangePackageText2'>Enter the marketer's store</div>
          //     </div>
          //   </div>

          //   <div className="ChangePackageimg">
          //     <img src={ic_back} />


          //   </div>
          // </div>
        }




        <BottomSheet onDismiss={onDismiss} open={SuccessB}>
          <div className='RegisterBeforeBottomSheet'>
            <div className='RegisterBeforeBottomSheetHeader'>
              <div className='SuccessBBottomSheetHeaderText'>
                <VerifiedIcon style={{ "color": "#2E7D32", "marginRight": "8px" }} />
                Congratulations</div>
            </div>
            <div className='RegisterBeforeBottomSheetBody'>
              {parseInt(CountCoin).toLocaleString()} coins have been added to your account
            </div>
            {Point > 0 && Point != 'undefined'? <div style={{ "marginTop": "4px", "marginBottom": "4px" }}>+</div> : null}
            <div>
              {Point > 0 && Point != 'undefined'? <div className='pointInBottomSheet'>{Point} <img src={xpplus} className='XpCountInlistIcon' style={{ "width": "14px", "height": "14px", "marginLeft": "4px" }} /></div> : null}
            </div>
            <div className='RegisterBeforeBottomSheetFooter'>
              {/* <button className='BottomSheetCancelButton' onClick={() => SuccessB(false)}>Cancel</button> */}
              <button className='BottomSheetSuccessB' onClick={() => RestartAfterUnSuccess()}>OK</button>
            </div>
          </div>
        </BottomSheet>

        <BottomSheet onDismiss={onDismiss} open={UnSuccessB}>
          <div className='RegisterBeforeBottomSheet'>
            <div className='RegisterBeforeBottomSheetHeader'>
              <div className='SuccessBBottomSheetHeaderText'>
                <ErrorOutlinedIcon style={{ "color": "#D32F2F", "marginRight": "8px" }} />
                Unsuccessful payment!</div>
            </div>
            {/* <div className='RegisterBeforeBottomSheetBody'>
              {CountCoin} coins have been added to your account
            </div> */}
            <div className='RegisterBeforeBottomSheetFooter'>
              {/* <button className='BottomSheetCancelButton' onClick={() => SuccessB(false)}>Cancel</button> */}
              <button className='BottomSheetSuccessB' onClick={() => RestartAfterUnSuccess()}>Got it</button>
            </div>
          </div>
        </BottomSheet>
      </div>
    </div>

  )
}

export default Buy