import React, { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField';
import { setMiniLoading } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import BackImg from './../Public/Image/Login/Back.svg'
import Bannedicon from './../Public/Image/Bannedicon.svg'
import data from './../Auth/country.json';
import './SellingPhoneNumbers.css'
import axios from "axios";
import { BottomSheet } from 'react-spring-bottom-sheet'
import Snackbar from '@mui/material/Snackbar';
import { AllApi } from './../Api/AllApi'
import SaleTooManyAttempts from './../BottomSheet/SaleTooManyAttempts'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { SetSaleTooManyAttempts } from './../app/Home'


const SellingPhoneNumbers = () => {


  const [PhoneNumber, setPhoneNumber] = useState('')
  const history = useHistory();
  // const Session = useSelector((state) => state.counter.Session)
  // const [ErrorLogin, setErrorLogin] = useState(false)
  const [PHONE_NUMBER_INVALID, setPHONE_NUMBER_INVALID] = useState(false)
  const [PHONE_NUMBER_BANNED, setPHONE_NUMBER_BANNED] = useState(false)
  // const [ConnectionError, setConnectionError] = useState(false)
  // const TelegramAccount = useSelector((state) => state.JoinState.TelegramAccount)
  const [DuplicateNumber, setDuplicateNumber] = useState(false)
  const [DuplicateNumber24H, setDuplicateNumber24H] = useState(false)
  const [FloodWait, setFloodWait] = useState(false)
  // const [FloodWaitTime, setFloodWaitTime] = useState(0)
  // const State = useSelector((state) => state.counter.Authentication)
  const dispatch = useDispatch()
  const MiniLoading = useSelector((state) => state.Home.MiniLoading)

  const [CountryCode, setCountryCode] = useState('')
  const [CountryName, setCountryName] = useState('')
  const [CountryError, setCountryError] = useState(false)
const [BlockedNumberServer, setBlockedNumberServer] = useState(false)


  const SendCodeFunction = async () => {



    if (CountryName == '') {
      setCountryError(true)

    } else {
      dispatch(setMiniLoading(true))
   


      if (PhoneNumber) {
    
        if (PhoneNumber.length > 0) {
         
        

          const MakeApiClass = new AllApi();
          let res = await MakeApiClass.GetSellPhoneNumber(CountryCode + PhoneNumber)

     
          if (res.code == 301) {
            // 24 hourse 5 min
            setDuplicateNumber(true)
            dispatch(setMiniLoading(false))

          }
          else if (res.code == 302) {
            dispatch(setMiniLoading(false))
            // 24h
            setDuplicateNumber24H(true)

          }
          else if (res.code == 303) {
            dispatch(setMiniLoading(false))

            setInvalidValue(true)

          } else if (res.code == 304) {
            dispatch(setMiniLoading(false))

            setPHONE_NUMBER_BANNED(true)
          } else if (res.code == 305) {
            // flodd wait
            // SaleTooManyAttempts
            dispatch(setMiniLoading(false))

            dispatch(SetSaleTooManyAttempts(true))
          } else if (res.code == 306) {
            // blocked number
            // SaleTooManyAttempts
            // dispatch(SetSaleTooManyAttempts(true))
            dispatch(setMiniLoading(false))

            setBlockedNumberServer(true)
          } else if (res.code == 200) {
            dispatch(setMiniLoading(false))
            history.push(`/VerificationCode?number=${'+' + CountryCode + PhoneNumber}`);

          } else {
            dispatch(setMiniLoading(false))
            setSnackbaropen(true);
          }

        }
      } else {
        setInvalidValue(true)
        dispatch(setMiniLoading(false))

      }
    }


  }
  const [Snackbaropen, setSnackbaropen] = useState(false);
  const handleCloseSnackbaropen = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbaropen(false);
  };

  const [InvalidValue, setInvalidValue] = useState(false)


  useEffect(() => {
    axios.get('http://ip-api.com/json/')
      .then(response => {
       
      })
      .catch(error => {
        console.error(error);
      });
  }, []);


  const GetIp = async () => {
    // const res = await axios.get("https://geolocation-db.com/json/");
    // const res = await axios.get("http://ip-api.com/json/");


//     https://ip-api.com
// https://ipwhois.io
// https://ipgeolocation.io
// https://ipstack.com

    const res = await axios.get("http://ip-api.com/json/");
    
    // const res = await axios.get("https://ipapi.co/json/");

    data.countries.map(item => {
 
      if (res.data.country.toLocaleLowerCase().indexOf(item.name.toLocaleLowerCase()) >=0) {
    

        let code = item.code.replace('+', '')
        setCountryName(item.name)
        setCountryCode(code)
      }
    })
  }
  useEffect(() => {
    GetIp()
  }, [])

  const PhoneNumberHandler = (e) => {
    setPhoneNumber(e.target.value)
    setInvalidValue(false)
    setPHONE_NUMBER_INVALID(false)
    setDuplicateNumber(false)
    setDuplicateNumber24H(false)
    setFloodWait(false)

  }

  const CountryCodeHandler = (e) => {
    setCountryError(false)
    setBlockedNumberServer(false)
    let value = e.target.value
    value = value.trim()

    if (value.startsWith('+')) {
      value = value.replace('+', '')
    }

    for (let index = 0; index < data.countries.length; index++) {
      const item = data.countries[index];
      if (item.code.replace(/\s/g, '') == '+' + value) {
        setCountryName(item.name)
        setCountryCode(item.code)
        setCountryError(false)
        break;
      } else {
        setCountryName('')
      }


    }

    setCountryCode(e.target.value)
  }


  function onDismiss() {
    setTimeout(() => {

      setFloodWait(false)
    }, 300);


  }
  function onDismissBanned() {
    setTimeout(() => {

      setPHONE_NUMBER_BANNED(false)
    }, 300);


  }
  return (
    <div className='SellingPhoneNumbersContainer'>
      <div className='SellingPhoneNumbersHeader'>
        <Link to="/Coin?Status=Sale" className="RegisterBackIconSellingPhoneNumbers" >
          <img src={BackImg} className="RegisterBackIconImg" />

        </Link>
        <div className='SellingPhoneNumbersHeaderText'>
          Account sale
        </div>
      </div>
      <div className='SellingPhoneNumbersBody'>

        <div className='SellingPhoneNumbersBodyText'
        //  onClick={handleClickOpen}
        >

          Please enter the account number and country code
          you want to sell
        </div>
        <div className='SellingPhoneNumbersInput'>
          {
            CountryError ?
              <TextField
                error
                id="outlined-error"
                variant="outlined"
                className='SellingPhoneNumbersCountryTextField' type='number'
                value={CountryCode}
                // onClick={handleClickOpen}
                onChange={(e) => CountryCodeHandler(e)}
                helperText={``}
                min={0}
                inputProps={{
                  maxLength: 5,
                  type: 'number',
                }}
              />
              :
              BlockedNumberServer?
              <TextField
              error
              id="outlined-error"
              variant="outlined"
              className='SellingPhoneNumbersCountryTextField' type='number'
              value={CountryCode}
              // onClick={handleClickOpen}
              onChange={(e) => CountryCodeHandler(e)}
              helperText={``}
              min={0}
              inputProps={{
                maxLength: 5,
                type: 'number',
              }}
            />
              :
              <TextField
                value={CountryCode}

                // onClick={handleClickOpen}
                onChange={(e) => CountryCodeHandler(e)}
                id="outlined-basic" variant="outlined"
                className='SellingPhoneNumbersCountryTextField'
                type='number'
                min={0}
                inputProps={{
                  maxLength: 5,
                  type: 'number',
                }} />
          }



          {
            // FloodWait ?
            //     <TextField
            //         error
            //         id="outlined-error-helper-text"
            //         helperText={`FloodWait ${FloodWaitTime}`}
            //         value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)}
            //         label="Phone number" className='SellingPhoneNumbersPhoneTextField' type='number' />
            //     :
            DuplicateNumber ?
              <TextField
                error
                id="outlined-error-helper-text"
              helperText={`Phone number has already been registered, please confirm it`}

                value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)}
                label="Phone number" className='SellingPhoneNumbersPhoneTextField' type='number' />
              :
              DuplicateNumber24H?
              <TextField
              error
              id="outlined-error-helper-text"
              helperText={`You have already sold this account`}

              value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)}
              label="Phone number" className='SellingPhoneNumbersPhoneTextField' type='number' />
              :
              // ConnectionError ?
              //     <TextField
              //         error
              //         id="outlined-error-helper-text"
              //         helperText={`ConnectionError`}
              //         value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)} label="Phone number" className='SellingPhoneNumbersPhoneTextField' type='number' />
              //     :
              InvalidValue ?
                <TextField
                  error
                  id="outlined-error-helper-text"
                  helperText={`Invalid number, please enter a valid number`}
                  value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)} label="Phone number" className='SellingPhoneNumbersPhoneTextField' type='number' />
                :
                PHONE_NUMBER_INVALID ?
                  <TextField
                    error
                    id="outlined-error-helper-text"
                    helperText={`Phone number is incorrect`}
                    value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)} label="Phone number" className='SellingPhoneNumbersPhoneTextField' type='number' /> :
                  CountryError ?
                    <TextField
                      error
                      id="outlined-error-helper-text"
                      helperText={`Country code  is incorrect`}
                      value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)} label="Phone number" className='SellingPhoneNumbersPhoneTextField' type='number' />
                    :
                    BlockedNumberServer?
                    <TextField
                    error
                    id="outlined-error-helper-text"
                    helperText={`This country code is currently unavailable. Please enter another country code`}
                    value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)} label="Phone number" className='SellingPhoneNumbersPhoneTextField' type='number' />
                    :
                    <TextField value={PhoneNumber} onChange={(e) => PhoneNumberHandler(e)} label="Phone number" className='SellingPhoneNumbersPhoneTextField' type='number' />
          }


        </div>



      </div>
      <div className='SellingPhoneNumbersFooter'>
        <div className='SellingPhoneNumbersLinkDiv'>

          <button
            // className={!MiniLoading?"SellingPhoneNumbersLink":"SellingPhoneNumbersLinkdisableButton"}
            className="SellingPhoneNumbersLink"
            onClick={() => MiniLoading ? null : SendCodeFunction()}

          >
            {MiniLoading ?
              <div className="loadminijoin">

              </div>
              :
              'Send verification code'

            }
          </button>

        </div>



      </div>

      <BottomSheet onDismiss={onDismiss} open={FloodWait}>
        <div className='RegisterBeforeBottomSheet'>
          <div className='RegisterBeforeBottomSheetHeader'>
            <div className='RegisterBeforeBottomSheetHeaderText'>Number limitation</div>
          </div>
          <div className='RegisterBeforeBottomSheetBody'>
            <span className='PhoneNumberInBottomSheet'>+{CountryCode + PhoneNumber}</span> is restricted, and you cannot log in with it. Please wait for a while or use another number
          </div>
          <div className='RegisterBeforeBottomSheetFooter'>
            {/* <button className='BottomSheetCancelButton' >Cancel</button>  */}
            <button style={{ 'width': '100%' }} className='BottomSheetLoginButton' onClick={() => setFloodWait(false)} >Got it</button>
          </div>
        </div>
      </BottomSheet>
<SaleTooManyAttempts />
      <BottomSheet onDismiss={onDismissBanned}
        open={PHONE_NUMBER_BANNED}
      // open={true}
      >
        <div className='RegisterBeforeBottomSheet'>
          <div className='RegisterBeforeBottomSheetHeader'>
            <div className='RegisterBeforeBottomSheetHeaderText'>Number is banned</div>
          </div>
          <div className='RegisterBeforeBottomSheetBodyBanned'>
            <span className='PhoneNumberInBottomSheet'><img src={Bannedicon} className='BannedImage' /></span> Please enter another phone number
          </div>
          <div className='RegisterBeforeBottomSheetFooter'>
            {/* <button className='BottomSheetCancelButton' >Cancel</button>  */}
            <button style={{ 'width': '100%' }} className='BottomSheetLoginButton' onClick={() => setPHONE_NUMBER_BANNED(false)} >Got it</button>
          </div>
        </div>
      </BottomSheet>


      <Snackbar
        open={Snackbaropen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbaropen}
        message="Something went wrong! Please try again."

      />
    </div>
  )
}

export default SellingPhoneNumbers