import React from 'react'
import Back from './../Public/Image/Home/Back.svg'
import './GetLinkUsername.css'
import TextField from '@mui/material/TextField';
import ViewCheckUsername from '../BottomSheet/ViewCheckUsername';
import ShowPriceAndChannel from '../BottomSheet/ShowPriceAndChannel';
import { useState } from 'react';
import { setBottomSheetViewCheckUsername } from './../app/Home'
import { setUserNameForOrder } from './../app/Home'
import { GetCookie } from '../Action/GetCookie';
// import { CheckChannelBeforePurchase } from './../Api/CheckChannelBeforePurchase'
// import { AllApi } from './../Api/AllApi'

import { useSelector, useDispatch } from 'react-redux'
import TooManyOrders from './../BottomSheet/TooManyOrders'
import ViolatingChannel from './../BottomSheet/ViolatingChannel'
import { setBottomSheetTooManyOrders } from './../app/Home'
import { setBottomSheetViolatingChannel } from './../app/Home'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ViewShowPriceAndChannel from './../BottomSheet/ViewShowPriceAndChannel'
import { setBottomSheetViewShowPriceAndChannel } from './../app/Home'
import { setPostLink } from './../app/Home'


const GetUsernameView = () => {
    const Token = GetCookie('Token')
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const price = searchParams.get('price');
    const count = searchParams.get('count');
    const ByCoin = searchParams.get('ByCoin');
    const PostViewFilter = searchParams.get('PostViewFilter');

    const PostLink = useSelector((state) => state.Home.PostLink)
    const [LastUsername, setLastUsername] = useState('')
    const [Username, setUsername] = useState('')
    const [UsernameError, setUsernameError] = useState(false)
    const [UsernameErrorEmpty, setUsernameErrorEmpty] = useState(false)
    const dispatch = useDispatch()

    const HandleUsernameLink = async () => {


        if (LastUsername.length == 0) {
            setUsernameErrorEmpty(true)
        } else {
            if (UsernameError == false && UsernameErrorEmpty == false) {
                if (LastUsername.length < 4 && LastUsername.length >= 0) {
                    setUsernameError(true)
                } else {
                    if (PostViewFilter === '1') {
                        // OnePost
                    
                        dispatch(setUserNameForOrder(LastUsername))
                        dispatch(setBottomSheetViewShowPriceAndChannel(true))
                    } else {
                        // Multi Post
                        dispatch(setBottomSheetViewCheckUsername(true))
                        dispatch(setUserNameForOrder(LastUsername))
                    }

                  
                }
            }
        }
    }
    const HandleChange = (value) => {
        // OnePost
        let result = value.trim();
        result = result.toLowerCase();
        setUsername(result)

        setUsernameErrorEmpty(false)
        setUsernameError(false)
        setLastUsername(result)
        const resultid = extractUsernameAndPostId(value);
        
        if (resultid == 'Invalid URL length. Please provide a valid Telegram URL.') {
            if (value.length > 4) {

            setUsernameError(true)
            }
        } else {
            if (result.length > 0) {

                if (resultid) {
                    let responseV = isValidTelegramUsername(resultid.username)
                 
                    if (responseV == true) {
                        setLastUsername(resultid.username)
                        dispatch(setPostLink(resultid.postId))
                        setUsernameError(false)
                    } else {
                        setUsernameError(true)

                    }

                } else {
                    setUsernameError(true)
                  
                }
            } else {
                setUsernameError(false)
            }
        }

    }

    // const telegramUsernameRegex = /^[a-zA-Z][a-zA-Z0-9_]{3,31}$/;
    const telegramUsernameRegex = /^[a-zA-Z][a-zA-Z0-9_]{3,30}[a-zA-Z0-9]$/;

    function isValidTelegramUsername(username) {
        return telegramUsernameRegex.test(username);
    }

    function extractUsernameAndPostId(url) {
        const regex = /https:\/\/t.me\/([^\/]+)\/(\d+)/;
        const match = url.match(regex);
        let Error;
        if (match && match.length === 3) {
            const username = match[1];
            const postId = match[2];

            // Check if the entire URL matches the expected format
            if (url.length === match[0].length) {
                return { username, postId };
            } else {
                Error = 'Invalid URL length. Please provide a valid Telegram URL.'
                // throw new Error('Invalid URL length. Please provide a valid Telegram URL.');
            }
        } else {
            Error = 'Invalid URL length. Please provide a valid Telegram URL.'

        }
        return Error
    }

    const HandleChangeUsername = (value) => {

        let result = value.trim();
        setUsername(result)
        setUsernameErrorEmpty(false)
        setUsernameError(false)

        let PositionLink = value.search("https://t.me/");//11 length
        let PositionSign = value.search("@");
        const startsWithAlphabetRegex = /^[A-Za-z]/;
        let username
        if (PositionLink == 0) {
            // let LinkHttps = result.slice(0, 13);
            username = result.replace('https://t.me/', '');

        } else if (PositionSign == 0) {
            // let LinkSign = result.slice(0, 1);
            username = result.replace('@', '');

        } else {
            username = result
        }
        // let SatrtWitheAlphabet = startsWithAlphabetRegex.test(username);
        setLastUsername(username)
     
        if (username.length > 4) {
        if (isValidTelegramUsername(username)) {
            setUsernameError(false)

        } else {
            setUsernameError(true)
        }
    }

    }



    return (
        <div className='GetLinkUsername'>
            <div className='GetLinkUsernameHeader'>
                <Link to="/Home?Status=View" className="GetLinkUsernameHeaderLink">  <img src={Back} className='GetLinkUsernameHeaderImg' />
                </Link>
                <div className='GetLinkUsernameHeaderText'>
                    {
                        PostViewFilter == '1' ? 'Post link' : 'Username'
                    }
                </div>
            </div>
            <div className='GetLinkUsernameContent'>
                <div className='GetLinkUsernameContentText1'>
                    {
                        PostViewFilter == '1' ? 'Please enter the link to the desired post' : 'Please enter your channel username'
                    }
                </div>
                <div className='GetLinkUsernameInputContainer'>
                    {

                        UsernameErrorEmpty ?
                            <TextField
                                error
                                id="outlined-error"
                                label={PostViewFilter == '1' ? "Post link" : "Username"}
                                defaultValue="Hello World"
                                className='GetLinkUsernameInput'
                                onChange={(e) => PostViewFilter == '1' ? HandleChange(e.target.value) : HandleChangeUsername(e.target.value)}
                                value={Username}
                                helperText="field is empty"
                            />
                            :
                            UsernameError ?
                                <TextField
                                    error
                                    id="outlined-error"
                                    label={PostViewFilter == '1' ? "Post link" : "Username"}
                                    defaultValue="Hello World"
                                    className='GetLinkUsernameInput'
                                    onChange={(e) => PostViewFilter == '1' ? HandleChange(e.target.value) : HandleChangeUsername(e.target.value)}
                                    value={Username}
                                    helperText="format is incorrect"
                                />
                                : <TextField id="outlined-basic"
                                    label={PostViewFilter == '1' ? "Post link" : "Username"}
                                    variant="outlined"
                                    className='GetLinkUsernameInput'
                                    onChange={(e) => PostViewFilter == '1' ? HandleChange(e.target.value) : HandleChangeUsername(e.target.value)}
                                    value={Username}
                                />
                    }


                </div>
                <div className='GetLinkUsernameContentText'>
                    {PostViewFilter == '1' ? `Tab the desired post and choose "Copy Post Link" option and then paste it here` : 'Please ensure the following before placing an order:'}
                </div>
                <ul className='GetLinkUsernameContentTextUl'>
                    {PostViewFilter == '1' ?
                        null
                        : <li>
                            The channel must be public
                        </li>}
                </ul>
            </div>
            <div className='GetLinkUsernameFooter'>
                <button className='GetLinkUsernameFooterButton' onClick={() => HandleUsernameLink()}>
                    Next
                </button>
            </div>
            {
                ByCoin ? <ViewShowPriceAndChannel /> : <ViewShowPriceAndChannel />
            }
            <ViewCheckUsername />

            <TooManyOrders />
            <ViolatingChannel />
        </div>
    )
}

export default GetUsernameView