import React from 'react'
import './Payment.css'
import { Link } from 'react-router-dom';
import Back from './../Public/Image/BuyCoin/Back.svg'
import PaymentEmpty from './../Public/Image/Profile/PaymentEmpty.svg'
import { useHistory } from "react-router-dom";

import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { useState, useEffect } from 'react';
import { GetCookie } from './../Action/GetCookie'
import PaymentMember from './PaymentMember'
import PaymentCoin from './PaymentCoin'
import PaymentView from './PaymentView'
// import { GetPayment } from './../CoinApi/GetPayment'
import { AllApi } from './../Api/AllApi'

import { TelegramDB } from './../DataBase/TelegramDB'
import Loading from './../Loading'


const Payments = () => {
  let Token = GetCookie('Token');
  const history = useHistory();

  const [Filter, setFilter] = useState('all')
  const FilterLocal = localStorage.getItem('PaymentFilter')
  useEffect(() => {

    if (FilterLocal) {
      setData('')
      ChangeFilter(FilterLocal)
      GetPaymentData(FilterLocal,1)
    } else {
      GetPaymentData('all')

    }
  }, [])

  const ChangeFilter = (input) => {
    setLoadingState(true)

    setFilter(input)
    setData('')
    localStorage.setItem('PaymentFilter', input)
    // if (Data != '') {
    GetPaymentData(input, 1)
    // }
    // setLoadingState(false)

  }
  const [Data, setData] = useState('')
  const [ShowMore, setShowMore] = useState(false)
  const [NowPAge, setNowPAge] = useState(1)


  const [LoadingState, setLoadingState] = useState(true)
  const GetPaymentData = async (filter, Now) => {
 
    if (Now == 1 && Data.length == 0|| Now == '1' && Data.length == 0) {
      setLoadingState(true)
      // setLoadingState(true)
    }

    let TelegramAccount = await TelegramDB.Data.toArray()
    setShowMore(false)

    let phonenumbers = []
    if (TelegramAccount) {
      if (TelegramAccount.length > 0) {
        TelegramAccount.map(item => {
          phonenumbers.push(item.PhoneNumber)
        })
      }
    }


let LocalData;
   
    const MakeApiClass = new AllApi();
    let res = await MakeApiClass.GetPayment(Token, phonenumbers, filter, Number(Now))
    // let res = await GetPayment(Token, phonenumbers, filter, Number(Now))

    if (res.code == 200) {
      let All = res.data.data.pages
     

      if (All > Number(res.data.data.page)) {
      
        setMiniLoadingState(true)
        setNowPAge(Number(res.data.data.page) + 1)
        setShowMore(true)

        setMiniLoadingState(false)
      } else {
      

        setShowMore(false)
        setMiniLoadingState(false)
        setNowPAge(Number(res.data.data.page))

      }
    
      LocalData=res.data.data.docs;
      if (Number(res.data.data.docs.length) > Number(LocalData.length)  ) {
        for (let index = 0; index < res.data.data.docs.length; index++) {

          setData((prevState) => [...prevState, res.data.data.docs[index]]);


        }
      }else{
         setData(res.data.data.docs)
      }

     

    }else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
      let name = "Token"
      let name1 = "active"
      document.cookie = name + '=; Max-Age=-99999999;';
      document.cookie = name1 + '=; Max-Age=-99999999;';
      history.push("/");
      // dispatch(setLoading(false))

  }
    phonenumbers = []

    setLoadingState(false)


  }



  const [MiniLoadingState, setMiniLoadingState] = useState(false)

  const handleScroll = (e) => {
    const bottom = (e.target.scrollHeight - e.target.scrollTop) - 100 <= e.target.clientHeight;

    if (bottom) {
      // alert(e.target.scrollHeight - e.target.scrollTop)
  
      // alert(e.target.clientHeight)
      //     alert(bottom)

 
      // setPage(prevState => prevState + 1);


      GetPaymentData(Filter, NowPAge)



    } else {
    
    }
  }

  return (
    <div className='PaymentContainer'   >
      <div className='TransferHistoryHeader'>
        <Link to="/Profile">
          <img src={Back} className='TransferHistoryHeaderImg' />
        </Link>
        <div className='TransferHistoryHeaderText'>Payments</div>
      </div>
      <div className='PaymentBody' onScroll={(e) => handleScroll(e)}>
        <div className='PaymentFilter'>

          <div className={Filter == "all" ? 'ActiveFilterPayment' : "PaymentFilterItem"}
            onClick={() => ChangeFilter("all")}>
            {Filter == "all" ? <DoneOutlinedIcon className='DoneOutlinedIcon1' /> : null}
            All
          </div>

          <div className={Filter == "member" ? 'ActiveFilterPayment' : "PaymentFilterItem"}
            onClick={() => ChangeFilter("member")}>
            {Filter == "member" ? <DoneOutlinedIcon className='DoneOutlinedIcon1' /> : null}
            Member
          </div>

          <div className={Filter == "view" ? 'ActiveFilterPayment' : "PaymentFilterItem"}
            onClick={() => ChangeFilter("view")}>
            {Filter == "view" ? <DoneOutlinedIcon className='DoneOutlinedIcon1' /> : null}
            View
          </div>

          <div className={Filter == "coin" ? 'ActiveFilterPayment' : "PaymentFilterItem"}
            onClick={() => ChangeFilter("coin")}>
            {Filter == "coin" ? <DoneOutlinedIcon className='DoneOutlinedIcon1' /> : null}
            Coin
          </div>

        </div>


        <div className='PaymentBody1'  >
          <div className='PaymentItems' >
            {LoadingState ? <Loading /> :
              // Data ?
              Data != '' ?
                Data.map((item, i) =>
                  // i <1 ?
                  item.pay_member == true ?
                    <PaymentMember key={i} item={item} />
                    :
                    item.pay_view == true ?
                      <PaymentView key={i} item={item} />
                      :
                      item.coin > 0 ?
                        <PaymentCoin key={i} item={item} />
                        :
                        null

                )

                : <div className='PaymentEmptyDiv'>
                  <img src={PaymentEmpty} />
                </div>
            }
            {MiniLoadingState ?
              <div className="loadminiBlue" style={{ "marginTop": "16px" }}></div>
              : null}


            {
              // ShowMore ?
              //   <button className="ShowMore" onClick={() => GetPaymentData(Filter, NowPAge)}>
              //     {/* <AddIcon className='ShowmoreIcon' /> */}
              //     more
              //   </button>
              //   : null
            }

          </div>

        </div>
      </div>
    </div>
  )
}

export default Payments