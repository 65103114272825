import React from 'react'
import User from './../Public/Image/Profile/Person.svg'
import Coin from './../Public/Image/BuyCoin/Coin.svg'
import Snackbar from '@mui/material/Snackbar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import moment from 'moment';

const PaymentMember = ({item}) => {
 
    const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
      });
      const { vertical, horizontal, opens } = state;
      const handleClick = (newState) => {
        setState({ ...newState, opens: true });
      };
      const handleClose = () => {
        setState({ ...state, opens: false });
      };
      const copyText = async (value) => {
   
        try {
          await navigator.clipboard.writeText(value);
          handleClick({ vertical: 'bottom', horizontal: 'center' })
        } catch (err) {
          console.error('Unable to copy text to clipboard', err);
        }
      }
    return (
        <div className='PaymrntItem'>

            <div className='PaymentItemRow'>
                <div className='PaymentKey'>Product</div>
                <div className='PaymentValue'>{parseInt(item.member_count).toLocaleString()} <img src={User} /></div>
            </div>

            {/* <div className='PaymentItemRow'>
                <div className='PaymentKey'>Coin Equivalent</div>
                <div className='PaymentValue'>700 <img src={Coin} /></div>
            </div> */}

            <div className='PaymentItemRow'>
                <div className='PaymentKey'>Price</div>
                <div className='PaymentValue'>{item.price} {item.market == 'zarinpal'? 'T' : '$'} </div>
            </div>

            <div className='PaymentItemRow'>
                <div className='PaymentKey'>Date</div>
                <div className='PaymentValue Date'>
          <span className='DateText'>
          {
                                                // item.date
                                                moment(item.dateTime).calendar(null, {
                                                    sameDay: '[Today] , HH : MM',
                                                    nextDay: '[Tomorrow]',
                                                    nextWeek: 'dddd',
                                                    lastDay: '[Yesterday] , HH : MM',
                                                    // lastWeek: '[Last] dddd',
                                                    lastWeek: 'DD MMM , YYYY',
                                                    sameElse: 'DD MMM , YYYY'
                                                })
                                            }
           
          </span>
         
         
          <span className='DateTimeText'> 
            {/* {item.time} */}
          </span>
        </div>
            </div>

            <div className='PaymentItemRow'>
                <div className='PaymentKey'>ID</div>
                <div className='PaymentValue'>{item.username}</div>
            </div>

            <div className='PaymentItemRow'>
                <div className='PaymentKey'>Store</div>
                <div className='PaymentValue'>{item.market} </div>
            </div>

            <div className='PaymentItemRow'>
                <div className='PaymentKey'>Token</div>
                <div className='PaymentValue Token' onClick={(e) => copyText(item.token)}>
                {
                        item.token.length > 15 ?
                            item.token.substring(0, 15) + '...' :
                            item.token
                    }
                    <ContentCopyIcon className='CopyIDOrder' />

                  </div>
            </div>

            <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={opens}
        onClose={handleClose}
        // message={` Password changed`}
        key={vertical + horizontal}
        autoHideDuration={3000}
        message={<span id="client-snackbar">
          {/* <CheckCircleIcon className='CheckIcon'/> */}
          The token has been copied

</span>}

      />
        </div>
    )
}

export default PaymentMember