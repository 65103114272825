import React from 'react'
import { useState, useEffect } from 'react'
import { TelegramClient, Api } from 'telegram'
import { StringSession } from 'telegram/sessions'
import { API_ID, API_HASH } from './../Const'
import { useSelector, useDispatch } from 'react-redux'
import { UpdateStateTelegram, SaveSession } from './../app/Slice'
import './VerifyCode.css'
import { Link } from 'react-router-dom';
import BackImg from './../Public/Image/Login/Back.svg'
import { setMiniLoading } from './../app/Home'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useHistory } from 'react-router-dom';
import { TelegramDB } from './../DataBase/TelegramDB'
import TextField from '@mui/material/TextField';
import { SetUserTelegramPasswordStatus } from './../app/JoinState'
// import { SetTelegramPassword } from './../app/JoinState'
import { BottomSheet } from 'react-spring-bottom-sheet'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import data1 from './../Auth/country2.json';
// import { AllApi } from './../Api/AllApi'
import { GetCookie } from './../Action/GetCookie'

const VerifyCode = () => {
    const MiniLoading = useSelector((state) => state.Home.MiniLoading)
    const history = useHistory();
    const PhoneCodeHash = useSelector((state) => state.counter.PhoneCodeHash)
    const PhoneNumber = useSelector((state) => state.counter.PhoneNumber)
    const Session = JSON.parse(localStorage.getItem('session'))
    const PhoneNumberLocal = localStorage.getItem('PhoneNumber')
    const dispatch = useDispatch()
    const UserTelegramPasswordStatus = useSelector((state) => state.JoinState.UserTelegramPasswordStatus)
    // const TelegramPassword = useSelector((state) => state.JoinState.TelegramPassword)
    const TelegramAccount = useSelector((state) => state.JoinState.TelegramAccount)
    const setCodeErrorSetLocalAllow = localStorage.getItem("setCodeErrorSetLocalAllow")
    const PassWord2Step = localStorage.getItem("PassWord2Step")

    useEffect(() => {

        if (!PhoneNumberLocal) {
            history.push(`/LoginTelegram`);
        }
        if (!Session) {
            dispatch(SaveSession(JSON.parse(localStorage.getItem('session'))))
        } else {
           
        }
    }, [])
    const RemoveLogOutedNumber = async (id) => {
        try {
            const id1 = await TelegramDB.Data.delete(id);
            return true
        } catch (error) {
          
        }
    }
    const CheckBeforeSendCode = async () => {
        let Duplacate = []
        await Promise.all(TelegramAccount.map(async (item) => {
          
            if (item.PhoneNumber == PhoneNumberLocal) {


                // if (item.Session == false) {
                Duplacate.push(true)
                let res = await RemoveLogOutedNumber(item.id)
                // } else {
                //     setDuplicateNumber(true)
                //     Duplacate.push(true)
                //     dispatch(setMiniLoading(false))
                // }


            } else {
                Duplacate.push(false)
            }
        })
        )
        return Duplacate
    }
    async function AddTelegramSession(ProfilePhoto) {
        let SaveRes = GetCookie('SaveAaccount')

        const User = JSON.parse(localStorage.getItem('user'));
        const CountryCode1 = JSON.parse(localStorage.getItem('CountryCode'));
        const FirstTime = true
        let ResponseCheck = await CheckBeforeSendCode()
        let index = ResponseCheck.indexOf(true);
    
        let CountryName;
        data1.map(item => {
          
            // let itemName= item.Iso.replace('+', '');
           
            if (item.Iso.replace('+', '') == CountryCode1) {
                CountryName = item.countryCode
            }

        })
     
        SaveRes=SaveRes==null || SaveRes=='' || !SaveRes?false:true
        if (index < 0) {
            const BotSession = ''
            try {
                const id = await TelegramDB.Data.add({
                    Session, PhoneNumber, User, ProfilePhoto, BotSession, CountryName, FirstTime,SaveRes
                });

            } catch (error) {
             
            }
        }

    }




    const stringSession = new StringSession(Session);
    const Client = new TelegramClient(stringSession, Number(API_ID), API_HASH, {
        connectionRetries: 3000,
    });



    const handlePasswordChange = (value) => {
        setPasswordError(false)
        setPassword(value)
        localStorage.setItem('PassWord2Step', value)

    }


    const [CodeError, setCodeError] = useState(false)

    const [FloodWait, setFloodWait] = useState(false)
    function onDismiss() {
        setTimeout(() => {

            setFloodWait(false)
        }, 300);


    }
    const [Verifing, setVerifing] = useState(false)
    const [PhoneNumberInvalid, setPhoneNumberInvalid] = useState(false)
    const VerifyCodeFunction = async () => {
      
        localStorage.setItem('AllowSendCode', true)
        if (Code) {
            dispatch(setMiniLoading(true))
            setVerifing(true)
            let res1 = await Client.connect();

            // if (res1 == true|| res1 == undefined) {
            try {
                let InvalidCode = false;
                let InvalidPassword = false;
        

                let res = await Client.start({
                    phoneNumber: PhoneNumberLocal,
                    phoneCode: async () => await GetCode(),
                    password: async () => await GetPassword(),
                    onError: (error) => {
                 
                        if (error.errorMessage == 'AUTH_KEY_UNREGISTERED') {
                            dispatch(setMiniLoading(false))
                            InvalidCode = true
                            dispatch(SetUserTelegramPasswordStatus(false))
                        
                            Client.disconnect();
                        }
                        if (error.errorMessage === "PHONE_CODE_INVALID") {
                            localStorage.setItem('AllowSendCode', false)

                            setCodeError(true)
                            localStorage.setItem("setCodeErrorSetLocalAllow", true)
                            // setCodeErrorSetLocalAllow(true)
                            dispatch(setMiniLoading(false))
                            InvalidCode = true
                            dispatch(SetUserTelegramPasswordStatus(false))
                        }
                        if (error.errorMessage == "PASSWORD_HASH_INVALID") {
                            setPasswordError(true)
                            dispatch(setMiniLoading(false))
                            InvalidPassword = true
                        }
                        if (error.errorMessage == "PHONE_NUMBER_INVALID") {
                           
                            setCodeError(true)

                        }
                        if (error.errorMessage == "FLOOD") {
                            setFloodWait(true)

                            Client.disconnect();
                        }

                    }
                })
                setVerifing(false)
                localStorage.setItem("setCodeErrorSetLocalAllow", false)
                localStorage.setItem('AllowSendCode', false)

             
                if (CodeError == false && PasswordError == false) {
                    const result = await Client.invoke(
                        new Api.auth.SignIn({
                            phoneNumber: PhoneNumber,
                            phoneCodeHash: PhoneCodeHash,
                            phoneCode: Code,

                        })
                    );
           
                    localStorage.setItem("user", JSON.stringify(result.user))
                    const buffer = await Client.downloadProfilePhoto('me')
                    const blob = new Blob([buffer], { type: 'text/plain' });
                    const reader = new FileReader();
                    reader.onload = function () {
                        const base64String = reader.result;
                    
                        AddTelegramSession(base64String, PhoneNumber)
                    };
                    reader.readAsDataURL(blob);



                    await Client.invoke(
                        new Api.account.UpdateStatus({
                            offline: false,
                        })
                    );

                    // setFinalLogin(result)
                    localStorage.setItem('data', JSON.stringify(result))
                    localStorage.setItem(PhoneNumber, JSON.stringify(Client.session.save()))
                    dispatch(UpdateStateTelegram('StateReady'))
                    dispatch(setMiniLoading(false))

                    history.push(`/JoinBot`);
                    setCode(null)
                    localStorage.removeItem('PassWord2Step')
                    // localStorage.removeItem('PhoneNumber')
                    localStorage.removeItem('Code')

                  
                } else {
           
                }

            } catch (error) {
            
                dispatch(setMiniLoading(false))
                if (error.errorMessage == "FLOOD") {
                    setFloodWait(true)

                    // Client.disconnect();
                }
                if (error.errorMessage == "PHONE_NUMBER_INVALID") {
                    setPhoneNumberInvalid(true)
       
                }
                if (error.errorMessage == "PHONE_PASSWORD_FLOOD") {
                    setFloodWait(true)

                }

            }
            // }


        } else {
            setCodeError(true)

        }




        dispatch(setMiniLoading(false))
        localStorage.removeItem('AllowSendCode')
        localStorage.removeItem('setCodeErrorSetLocalAllow')
        localStorage.removeItem('Code')
        localStorage.removeItem('PassWord2Step')
        localStorage.removeItem('AllowSend')
        localStorage.removeItem('PhoneNumber')
        localStorage.removeItem('CountryCode')


    }

    async function GetCode() {
        return new Promise(async function (resolve, reject) {
            const checkParam = async () => {

                const Interval = setInterval(() => {
                    let Code = localStorage.getItem('Code')
                    let AllowSendCode = localStorage.getItem('AllowSendCode')
                    if (Code == '' || Code == null) {

                      
                        checkParam();
                    } else {
                        // let Code = localStorage.getItem('Code') 
                       

                        if (AllowSendCode == 'true') {

                            resolve(Code);
                            clearInterval(Interval);
                        } else {
                            // setCodeError(false)
                            // setCodeError(true)
                            // setTimeout(() => {
                            checkParam();
                            // }, 4100);
                        }

                    }

                }, 4000);
            };

            // Start checking param
            checkParam();
            // localStorage.removeItem('Code')
            // localStorage.removeItem('AllowSendCode')
        });

    }
    async function GetPassword() {
        setCodeError(false)
        dispatch(setMiniLoading(false))
        setPassword(null)
        if (localStorage.getItem('Code') != null) {
            dispatch(SetUserTelegramPasswordStatus(true))
            return new Promise(async function (resolve, reject) {
                const checkParam = async () => {

                    const Interval = setInterval(() => {
                        let Password2 = localStorage.getItem('PassWord2Step')
                      
                        let AllowSend = localStorage.getItem('AllowSend')
                        if (Password2 == '') {
                            setPasswordError(true)
                           
                            checkParam();
                        } else {
                            if (AllowSend == 'true') {
                                

                                resolve(Password2);
                                clearInterval(Interval);
                                localStorage.removeItem('AllowSend')
                            }

                        }
                    }, 1000);

                };

                // Start checking param
                

                checkParam();
            });
        }

    }
    const setAllowSendCode = () => {
     
        dispatch(setMiniLoading(true))
        localStorage.setItem('AllowSendCode', true)
        // VerifyCodeFunction()
    }
    const setAllowSend = () => {
        dispatch(setMiniLoading(true))
        localStorage.setItem('AllowSend', true)
    }

    const [Password, setPassword] = useState('')
    const [Code, setCode] = useState('')
    const handleCode = (value) => {
        setCodeError(false)
        setCode(value)
     
        localStorage.setItem('Code', value)
    }
    const [showPassword, setshowPassword] = useState(false)
    const [PasswordError, setPasswordError] = useState(false)
    const togglePasswordVisibility = () => {
        setshowPassword(!showPassword);
    };
 
    return (
        <>
            {
                UserTelegramPasswordStatus == false ?
                    <div className='VerifyTelegramContainer'>

                        <div className='VerifyTelegramHeader'>
                            <Link to="/LoginTelegram" className='VerifyTelegramBackIcon'>
                                <img src={BackImg} className='VerifyTelegramBackIconImg' />
                            </Link>
                            <div className='VerifyTelegramHeaderText'>
                                Enter code
                            </div>
                        </div>
                        <div className='VerifyTelegramBody'>
                            <div className='VerifyTelegramInput'>
                                Please enter the code sent to your Telegram account


                            </div>
                            <div className='LoginTelegramWarning'>
                                {
                                    PhoneNumberInvalid ?
                                        <TextField
                                            error
                                            fullWidth
                                            variant="outlined"
                                            className='RegisterPasswordTextField'
                                            label="PhoneNumberInvalid"
                                            id="outlined-error-helper-text"
                                            helperText="PhoneNumberInvalid"
                                            type='text'
                                            value={Code}
                                            autoFocus
                                            onChange={(e) => handleCode(e.target.value)}

                                        />
                                        :
                                        CodeError ?
                                            <TextField
                                                error
                                                fullWidth
                                                variant="outlined"
                                                className='RegisterPasswordTextField'
                                                label="Code"
                                                id="outlined-error-helper-text"
                                                helperText="CodeError"
                                                type='text'
                                                value={Code}
                                                onChange={(e) => handleCode(e.target.value)}
                                                autoFocus
                                            />
                                            :
                                            <TextField
                                                autoFocus
                                                fullWidth
                                                variant="outlined"
                                                className='RegisterPasswordTextField'
                                                label="Code"
                                                id="Code"
                                                value={Code}
                                                onChange={(e) => handleCode(e.target.value)}
                                            />
                                }

                                {/*  */}

                            </div>


                        </div>
                        <div className='VerifyTelegramFooter'>
                            <div className='VerifyTelegramLinkDiv'>
                                {
                                    setCodeErrorSetLocalAllow ?
                                        <button className="VerifyTelegramLink"
                                            id={Verifing ? null : setCodeErrorSetLocalAllow == 'true' ? 'setAllowSendCode()' : 'VerifyCodeFunction()'}
                                            onClick={() => setAllowSendCode()}
                                        >
                                            {MiniLoading ?
                                                <div className="loadminijoin"></div>
                                                :
                                                <ArrowForwardIcon />
                                                // 'Next'
                                            }
                                        </button>
                                        :
                                        <button className="VerifyTelegramLink"
                                            id={Verifing ? null : setCodeErrorSetLocalAllow == 'true' ? 'setAllowSendCode()' : 'VerifyCodeFunction()'}
                                            onClick={() => Verifing ? null : setCodeErrorSetLocalAllow ? setAllowSendCode() : VerifyCodeFunction()}
                                        >
                                            {MiniLoading ?
                                                <div className="loadminijoin"></div>
                                                :
                                                <ArrowForwardIcon />
                                                // 'Next'
                                            }
                                        </button>
                                }

                            </div>
                        </div>

                    </div >
                    :
                    <div className='VerifyTelegramContainer'>

                        <div className='VerifyTelegramHeader'>
                            <Link to="/LoginTelegram" className='VerifyTelegramBackIcon'>
                                <img src={BackImg} className='VerifyTelegramBackIconImg' />
                            </Link>
                            <div className='VerifyTelegramHeaderText'>
                                Password
                            </div>
                        </div>
                        <div className='VerifyTelegramBody'>
                            <div className='VerifyTelegramInput'>
                                Please enter your Two-Step Verification password


                            </div>
                            <div className='LoginTelegramWarning'>
                                {
                                    PhoneNumberInvalid ?
                                        <TextField
                                            error
                                            autoFocus
                                            fullWidth
                                            variant="outlined"
                                            className='RegisterPasswordTextField'
                                            label={PassWord2Step ? Password : "Password"}
                                            id="outlined-error-helper-text"
                                            helperText="PhoneNumber Invalid"
                                            type={showPassword ? 'text' : 'password'}
                                            value={Password}
                                            onChange={(e) => handlePasswordChange(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    // Password ?
                                                    // Password.length > 0 ?
                                                    <InputAdornment position="end" className='IconShowHideError'>
                                                        <IconButton onClick={togglePasswordVisibility} className='IconShowHideError'>
                                                            {showPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                    //       : null
                                                    //   : null
                                                ),
                                            }}
                                        />
                                        :
                                        PasswordError ?
                                            <TextField
                                                error
                                                autoFocus
                                                fullWidth
                                                variant="outlined"
                                                className='RegisterPasswordTextField'
                                                label={Password ? '' : 'Password'}
                                                id="outlined-error-helper-text"
                                                helperText="invalid Password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={PassWord2Step ? PassWord2Step : ''}
                                                onChange={(e) => handlePasswordChange(e.target.value)}
                                                InputProps={{
                                                    endAdornment: (


                                                        <InputAdornment position="end" className='IconShowHideError'>
                                                            <IconButton onClick={togglePasswordVisibility} className='IconShowHideError'>
                                                                {showPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                                                            </IconButton>
                                                        </InputAdornment>

                                                    ),
                                                }}
                                            />

                                            :
                                            <TextField
                                                fullWidth
                                                autoFocus
                                                variant="outlined"
                                                className='RegisterPasswordTextField'
                                                label="Password"
                                                id="Password"
                                                type={showPassword ? 'text' : 'password'}
                                                value={Password}
                                                onChange={(e) => handlePasswordChange(e.target.value)}
                                                InputProps={{
                                                    endAdornment: (
                                                        // PassWord2Step ?
                                                        // PassWord2Step.length > 0 ?
                                                        <InputAdornment position="end" className='IconShowHide'>
                                                            <IconButton onClick={togglePasswordVisibility} className='IconShowHide'>
                                                                {showPassword ? <VisibilityOff className='Visibility' /> : <Visibility className='Visibility' />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                        //     : null
                                                        // :
                                                        // null

                                                    ),
                                                }}
                                            />
                                }


                                {/* <div className="code-input">
                                        <input type="text" maxLength="1" id='1' onInput={(e) => { setElement1(e.target.value); focusInput(1) }} />
                                        <input type="text" maxLength="1" id='2' onInput={(e) => { setElement2(e.target.value); focusInput(2) }} />
                                        <input type="text" maxLength="1" id='3' onInput={(e) => { setElement3(e.target.value); focusInput(3) }} />
                                        <input type="text" maxLength="1" id='4' onInput={(e) => { setElement4(e.target.value); focusInput(4) }} />
                                        <input type="text" maxLength="1" id='5' onInput={(e) => { setElement5(e.target.value); focusInput(5) }} />
                                    </div> */}
                            </div>


                        </div>
                        <div className='VerifyTelegramFooter'>
                            <div className='VerifyTelegramLinkDiv'>
                                <button className="VerifyTelegramLink"
                                    onClick={() => setAllowSend(true)}
                                >
                                    {MiniLoading ?
                                        <div className="loadminijoin"></div>
                                        :
                                        <ArrowForwardIcon />
                                        // 'Next'
                                    }
                                </button>
                            </div>
                        </div>

                    </div>

            }


            <BottomSheet onDismiss={onDismiss} open={FloodWait}>
                <div className='RegisterBeforeBottomSheet'>
                    <div className='RegisterBeforeBottomSheetHeader'>
                        <div className='RegisterBeforeBottomSheetHeaderText'>Number limitation</div>
                    </div>
                    <div className='RegisterBeforeBottomSheetBody'>
                        <span className='PhoneNumberInBottomSheet'>+{PhoneNumberLocal}</span> is restricted, and you cannot log in with it. Please wait for a while or use another number
                    </div>
                    <div className='RegisterBeforeBottomSheetFooter'>
                        {/* <button className='BottomSheetCancelButton' >Cancel</button>  */}
                        <button style={{ 'width': '100%' }} className='BottomSheetLoginButton' onClick={() => setFloodWait(false)} >Got it</button>
                    </div>
                </div>
            </BottomSheet>

        </>
    )
}

export default VerifyCode