import React, { useEffect, useState, useRef } from 'react'
import './Game.css'
import DoneIcon from '@mui/icons-material/Done';

import BottomNavigation from './../Home/BottomNavigation'
import MyLeagueComponent from './MyLeague/MyLeagueComponent';
import TopJoinerComponent from './TopJoiner/TopJoinerComponent';
import HeaderProgressBar from './HeaderProgressBar'
import GetName from './BottomSheets/GetName'
import { useSelector, useDispatch } from 'react-redux'
import Coin from './../Public/Image/Join/Coin.svg'


// import { AllApi } from '../Api/AllApi'

import NoInternet1 from './../Public/Image/Join/NoInternet.svg'
// import { CheckDataBaseGame } from './../GameAction/CheckDataBaseGame'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

import {
    SetAppSettingData,
    SetUserLeagueData,
    SetMyLeagueRank,
    SetLeagueLimitJoinProgress,
    SetLeagueMinimumJoinProgress,
    SetRewardLeague,
    SetMyLeagueRankNumber,
    SetGiftTopjoin,
    SetNameAccount,
    SetJoinCountRedux,
    SetPromotionBottomSheet,
    SetUserReward,
    SetClaimRewardCall,
    SetDataClaimReward,
    SetXpLimit,
    SetXpCount,
    SetLevelUserHeaderProgress,
    SetSnackBarEndLeagueState, SetIsBetweenLeaguge
} from './../app/Game'

import { Link } from 'react-router-dom';

import { GameDB } from './../DataBase/GameDB'
import Snackbar from '@mui/material/Snackbar';

import Loading from './../Loading'
import { OnlineRequestAppSettingGame } from '../GameAction/OnlineRequestAppSettingGame'
import { OnlineRequestProfileGame } from '../GameAction/OnlineRequestProfileGame'
import { OnlineRequestLeagueListGame } from '../GameAction/OnlineRequestLeagueListGame'
import { OnlineRequestTopJoinerGame } from '../GameAction/OnlineRequestTopJoinerGame'
import xpplus from './../Public/Image/Join/xpplus.svg'
import { AllApi } from './../Api/AllApi'
import { GetCookie } from '../Action/GetCookie'
import { setUserCoin } from './../app/Home'
import { SetEmail } from './../app/Slice'
import { db } from './../DataBase/db'
import { useHistory } from 'react-router-dom';
import Star from './../Public/Image/Game/Star.svg'

import HeadImg from './../../src/Public/Image/Game/Group 26086606.svg'
import { BottomSheet } from 'react-spring-bottom-sheet'
const Game = () => {
    const dispatch = useDispatch()
    // const [MyLeague, setMyLeague] = useState(false)
    const [MyLeague, setMyLeague] = useState(false)
    const GameLoading = useSelector((state) => state.Game.GameLoading)
    const history = useHistory();
    const CountJoining = useSelector((state) => state.JoinState.CountJoining)

    const UserCoin = useSelector((state) => state.Home.UserCoin)

    const JoinCountRedux = useSelector((state) => state.Game.JoinCountRedux)
    const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)
    const ClaimRewardCall = useSelector((state) => state.Game.ClaimRewardCall)
    const MyLeagueRank = useSelector((state) => state.Game.MyLeagueRank)
    const TopJoinerTime = useSelector((state) => state.Game.TopJoinerTime)
    const IsBetweenLeaguge = useSelector((state) => state.Game.IsBetweenLeaguge)
  const xpCountLeague = useSelector((state) => state.Game.xpCountLeague)

    const [LoadState, setLoadState] = useState(false)
    const [LastUpdate, setLastUpdate] = useState(0)
    const [NoInternet, setNoInternet] = useState(false)
    // const [IsBetweenLeaguge, setIsBetweenLeaguge] = useState(null)
    const [runInterval, setRunInterval] = useState(false);
    const [LastTime, setLastTime] = useState(0);
    const [CashTime, setCashTime] = useState(0);
    const intervalRef = useRef(null);
    // SnackBar /////////////////////////
    const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose = () => {
        setState({ ...state, opens: false });
    };
    const [open, setOpen] = useState(false)

    function onDismiss() {
        setTimeout(() => {
            setOpen(false)
        }, 300);
    }
    // // SnackBar//////////////////////////////


    useEffect(() => {
    
        if (NoInternet == true) {

            const timer = setInterval(async () => {
                let IsOnline = window.navigator.onLine
               
                if (IsOnline == true) {
                    clearInterval(timer);
                    // StartGame()

                }
            }, 10000);
          
        }
    }, [NoInternet])


    useEffect(() => {

        if (runInterval) {
            intervalRef.current = setInterval(() => {
                let NowInNow = new Date().getTime();
                let LastTimeLeague = localStorage.getItem('LastTimeLeague')
                let Distance = LastTimeLeague - NowInNow;
                // var minutescashTime = Math.floor(CashTime / 60);
                var minutes = Math.floor((Distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((Distance % (1000 * 60)) / 1000);
              

                if (minutes < 0) {
                    setLastUpdate(Math.abs(minutes))
                } else {
                    setLastUpdate(false)

                }
            }, 1000);
        }

        return () => {
            clearInterval(intervalRef.current);
        }; // clear interval when unmounting the component
    }, [runInterval, LastTime, CashTime]);





    const OfflineRequestLeagueList = async () => {


        let LeagueList = await GameDB.LeagueList.toArray()
        if (LeagueList.length > 0) {
         
            dispatch(SetUserLeagueData(LeagueList[0].LeagueList.data))
            dispatch(SetLevelUserHeaderProgress(LeagueList[0].LeagueList))
            let LevelUser = false
            LevelUser = LeagueList[0].LeagueList.data.userInformation.leagueLevel

            // let ResOfCalc = await GetUserDataFromAppSetting(LevelUser)


            let cacheTime = LeagueList[0].LeagueList.data.cacheTime
            let LastTime = LeagueList[0].LastTime
            clearInterval(intervalRef.current);
            localStorage.setItem('LastTimeLeague', LastTime)
            setLastTime(LastTime)
            setCashTime(cacheTime)
            setRunInterval(true)
        } else {
         
            // setNoInternet(true)
            const timer = setInterval(async () => {
                LeagueList = await GameDB.LeagueList.toArray()
               
                if (LeagueList.length == 1) {
                    clearInterval(timer);
                    OfflineRequestLeagueList()
                    // setNoInternet(false)
                } else {
                 
                }

            }, 1000);

        }

    }




    useEffect(() => {

        OfflineRequestLeagueList()

        return () => {

        }
    }, [IsBetweenLeaguge])



    useEffect(() => {

        localStorage.setItem("BadgeForGame", "game")

    }, [IsBetweenLeaguge, UserLeagueData])


    useEffect(() => {
        GetProfileFunction()
    }, [UserCoin])

    const Token = GetCookie('Token');

    const [PointDialogState, setPointDialogState] = useState('')
    const [xpCount, setxpCount] = useState('')
    const [xpLevel, setxpLevel] = useState('')
    const [xpLimit, setxpLimit] = useState('')
    const GetProfileFunction = async () => {
        const MakeApiClass = new AllApi();

        let AppSetting = await GameDB.AppSetting.toArray()
     
        if (AppSetting.length > 0) {
            setPointDialogState(AppSetting[0].AppSetting.PointDialog)

            let res = await MakeApiClass.GetProfile(Token)

            setxpCount(res.data.user.xpCount)
            setxpLevel(res.data.user.xpLevel)
            setxpLimit(res.data.user.xpLimit)
            if (res.code == 200) {
                dispatch(setUserCoin(res.data.user.coins))
                let res1 = db.Data.where({ id: Number(1) }).modify({ Email: res.data.user.email })
                dispatch(SetEmail(res.data.user.email))

            } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
                let name = "Token"
                let name1 = "active"
                document.cookie = name + '=; Max-Age=-99999999;';
                document.cookie = name1 + '=; Max-Age=-99999999;';
                history.push("/");
                // dispatch(setLoading(false))

            }
        }else{
            GetProfileFunction()
        }


    }
    const OpenBottomSheetShowXpDetails = () => {
        setBottomSheetState(true)
    }
    const [BottomSheetState, setBottomSheetState] = useState(false)
    function onDismiss() {
        setTimeout(() => {
            setBottomSheetState(false)
        }, 300);

    }

    function abbreviate_number(num, fixed) {
      
        if (num === null || num == undefined || num == false) { return null; } // terminate early
        if (num === 0) { return '0'; } // terminate early
        fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
        var b = (num).toPrecision(2).split("e"), // get power
            k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
            c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
            d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
            e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power

        return e;
    }
    return (
        <>
             <BottomSheet onDismiss={onDismiss} open={BottomSheetState}>
        <div className='RegisterBeforeBottomSheet'>
          <div className='RegisterBeforeBottomSheetHeader'>
            <div className='HeaderLogoBottomSheet' >
              Points in this event: <b style={{margin:"0 4px 0 4px"}}>{abbreviate_number(xpCountLeague)}</b>
              <img src={xpplus} className='XpCountInlistIcon' style={{ "width": "14px", "height": "14px" }} />

            </div>
         
          </div> 
            <div className='HeaderLogoBottomSheetText' >
            How can we get Points?

            </div>
          <ul className='BottomSheetUlXpUl'>
            {
              PointDialogState ?
                PointDialogState.map((item, i) =>
                  <li key={i} style={{ "marginTop": "8px" }}>{item}</li>
                )
                : null
            }

          </ul>

          <div className='RegisterBeforeBottomSheetFooter'> 
               <Link to="/Profile" className='BottomSheetCancelButton'  >Show All pts</Link>
            <button  className="BottomSheetLoginButton" onClick={() => setBottomSheetState(false)}>Got it</button>
        
          </div>
        </div>
      </BottomSheet>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={opens}
                onClose={handleClose}
                onDismiss={onDismiss}
                message="Something went wrong"
                key={vertical + horizontal}
                autoHideDuration={3000}
            />
            {
                !GameLoading ?
                    NoInternet ?
                        <>
                            <div className='JoinContainer'>
                                <div className='NoInterNetLogo'>
                                    <img src={NoInternet1} />
                                </div>
                                <div className='NoInterNetText'>
                                    Please make sure your device is connected to the internet
                                </div>
                                <div className='NoInterNetButton'>
                                    <button onClick={() => window.location.reload()}>
                                        Retry
                                    </button>
                                </div>

                            </div>
                        </>

                        :
                        IsBetweenLeaguge != null ?
                            UserLeagueData.topUsers ?
                                <div className='Game'>
                                    <div className='GameHeader'>
                                        <div className='GameHeaderInner'>
                                        <div className='HeaderLogo' onClick={() => OpenBottomSheetShowXpDetails()}>
        {/* <img src={Membersgram} className='HeaderLogoImg' /> */}
        {/* <div className='HeaderLogoPtLevel' >

          <div className='HeaderProgressBarImgContainer'> */}
        {/* <div className='HeaderProgressBarAbsoluteNumber' > */}
        {/* {xpLevel} */}
        {/* {xpCount} */}

        {/* </div> */}
        {/* <img src={Star} className='HeaderProgressBarImg' /> */}
        {/* </div>

        </div> */}
        <div className='HeaderLogoPt'>
          {/* <div> */}
          <img src={Star} className='HeaderProgressBarImg' />

          <span style={{ 'fontWeight': '700', fontSize: "12px", }}>
            {/* {abbreviate_number(xpLimit - xpCount)} */}
            {abbreviate_number(xpCountLeague)}
          </span>
          {/* Pts */}


          {/* </div> */}
          {/* <div> */}
          {/* To level {xpLevel ? xpLevel + 1 : null} */}
          {/* </div> */}
        </div>
      </div>
                                            {/* <div>
                                                Game
                                            </div> */}
                                            {/* <div className='GameHeaderProgress' >
                                            {UserCoin.toLocaleString()}
                                            </div> */}
                                            <Link to="/Coin?Status=Join" className='HeaderCoin'>
                                                {/* <div className='HeaderCoin'> */}
                                                <AddCircleRoundedIcon className='AddCoinIcon' />
                                                <div>
                                                    {UserCoin.toLocaleString()}
                                                </div>
                                                <div className='HeaderCoinImgContainer'>

                                                    {
                                                        CountJoining > 0 ?
                                                            <>
                                                                <div className='CoinHeaderImageLoader'></div>
                                                                <img src={Coin} className='HeaderCoinImg1' />
                                                            </>

                                                            :
                                                            <img src={Coin} className='HeaderCoinImg1' />

                                                    }

                                                </div>
                                                {/* </div> */}
                                            </Link >
                                        </div>

                                    </div>

                                    <div className='GameContent'>

                                        {
                                            // !IsBetweenLeaguge ?
                                            <div className='GameChips'>

                                                <div className={!MyLeague ? 'GameChipsItemLeft noSelect ActiveChips' : 'GameChipsItemLeft noSelect'} onClick={() => setMyLeague(false)}>
                                                    {!MyLeague ? <span><DoneIcon className={'GameChipsDone'} /></span> : null}
                                                    Top Players
                                                </div>

                                                <div className={MyLeague ? 'GameChipsItemRight noSelect ActiveChips' : 'GameChipsItemRight noSelect'} onClick={() => setMyLeague(true)}>
                                                    {MyLeague ? <span><DoneIcon className={'GameChipsDone'} /></span> : null}
                                                    My League
                                                </div>




                                                {/* <div className={MyLeague ? 'GameChipsItemLeft noSelect ActiveChips' : 'GameChipsItemLeft noSelect'} onClick={() => setMyLeague(true)}>
                                                    {MyLeague ? <span><DoneIcon className={'GameChipsDone'} /></span> : null}
                                                    My League
                                                </div>


                                                <div className={!MyLeague ? 'GameChipsItemRight noSelect ActiveChips' : 'GameChipsItemRight noSelect'} onClick={() => setMyLeague(false)}>
                                                    {!MyLeague ? <span><DoneIcon className={'GameChipsDone'} /></span> : null}

                                                    Top Joiners
                                                </div> */}
                                            </div>
                                            // : null
                                        }


                                        <div className='SelectComponent'>
                                            {

                                                MyLeague ?

                                                    <MyLeagueComponent MyLeagueRank={MyLeagueRank} LastUpdate={LastUpdate} />
                                                    :
                                                    <TopJoinerComponent IsBetweenLeaguge={IsBetweenLeaguge} Time={TopJoinerTime} LastUpdate={LastUpdate} />
                                            }




                                        </div>
                                        <GetName />
                                    </div>
                                    <div className='GameFooter'>

                                        <BottomNavigation />

                                    </div>
                                </div>

                                : <Loading />
                            : <Loading />
                    : <Loading />

            }

        </>
    )
}


export default Game