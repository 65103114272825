import React from 'react'
import { useLocation } from 'react-router-dom';
// import { MyOrderApiById } from './MyOrderApiById'
import { useEffect, useState } from 'react';
import Back from './../Public/Image/Orders/appbar.svg'
import DoneIcon from './../Public/Image/Orders/DoneIcon.svg'
import moment from 'moment';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Snackbar from '@mui/material/Snackbar';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import StopIcon from './../Public/Image/Orders/StopIcon.svg'
import ReportIcon from './../Public/Image/Orders/ReportIcon.svg'
import ProsessingIcon from './../Public/Image/Orders/prosessing.svg'
import { Link } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet'
import { AllApi } from './../Api/AllApi'
import { useHistory } from 'react-router-dom';
import { CalculateTwoTone } from '@mui/icons-material';

const OrderDetailsSingle = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const ID = searchParams.get('id');
  useEffect(() => {
    GetOrderById()
  }, [])
  const [Data, setData] = useState('')
  const GetOrderById = async () => {
    // let res = await MyOrderApiById(ID)
    const MakeApiClass = new AllApi();
    let res = await MakeApiClass.MyOrderApiById(ID)
  
    if (res.code == 200) {
      
      setData(res.data.data)
    } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
      let name = "Token"
      let name1 = "active"
      document.cookie = name + '=; Max-Age=-99999999;';
      document.cookie = name1 + '=; Max-Age=-99999999;';
      history.push("/");
      // dispatch(setLoading(false))

    }
  }

  const history = useHistory();


  const copyText = async (value) => {
    // Get the input element
  
    try {
      // Copy text to clipboard
      await navigator.clipboard.writeText(value);
    
      handleClick({ vertical: 'bottom', horizontal: 'center' })

    } catch (err) {
      console.error('Unable to copy text to clipboard', err);
    }
  }
  const [state, setState] = React.useState({
    opens: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, opens } = state;
  const handleClick = (newState) => {
    setState({ ...newState, opens: true });
  };
  const handleClose = () => {
    setState({ ...state, opens: false });
  };
  

  const [StatusBottomSheet, setStatusBottomSheet] = useState(false)
  function onDismiss() {
    setTimeout(() => {
      setStatusBottomSheet(false)
    }, 300);
  }
  const OpenBottomSheetInProccess = () => {
    setStatusBottomSheet(true)
  }
  const ReasonStop = [
    {
      code: 1,
      discription_en: 'Channel or group not be found!\n\nReasons:\n- Unauthorized content including drug sales, sexual, bet and etc.\n- Has a scam badge from Telegram\n- Failure to comply with copyright laws',
      discription_fa: 'کانال یا گروه پیدا نمیشود! \n\nدلایل: \n- محتوای غیر مجاز شامل فروش مواد مخدر، جنسی، شرطبندی و ...\n- دارای نشان کلاهبرداری از تلگرام\n- عدم رعایت قوانین کپی\u200cرایت'
    },
    {
      code: 2,
      discription_en: 'There is no such channel or group in with this address in Telegram.\nIt may have been deleted or their address changed during getting member process.',
      discription_fa: 'چنین کانال یا گروهی در تلگرام با این آدرس وجود ندارد.\n ممکن است کانال حذف شده باشد یا در حین ممبرگیری، آدرس آن تغییر یافته است.'
    },
    {
      code: 3,
      discription_en: 'The channel was stopped by Membersgram.\n\nreasons:\n- Content contrary to the laws\n- Betting and gambling',
      discription_fa: 'کانال توسط ناظر حقوقی ممبرزگرام متوقف شد.\n\nدلایل:\n- محتوای خلاف قوانین جمهوری اسلامی ایران\n- سیاسی و تفرقه افکن\n- شرط\u200cبندی و قمار\nو...'
    },
    {
      code: 4,
      discription_en: 'The channel or group is of the type of accepting the request by admin, and it is currently not possible to receive members for it.',
      discription_fa: 'کانال یا گروه، از نوع قبول درخواست توسط ادمین است و امکان دریافت ممبر برای آن در حال حاضر وجود ندارد.'
    }
  ];

  const [StopBottomSheet, setStopBottomSheet] = useState(false)
  const [ReasonDes, setReasonDes] = useState('')

  function onDismiss1() {
    setTimeout(() => {
      setStopBottomSheet(false)
    }, 300);
  }
  const OpenStopBottomSheet = () => {
    setStopBottomSheet(true)
  }
  const CalculateReason = () => {
    ReasonStop.map(item => {
      if (item.code == Data[0].reasonStopNumber) {
        setReasonDes(item.discription_en)

        OpenStopBottomSheet()
      }
    }

    )
  }
  return (
    <div className='OrderDetailsSingle'>
      <div className='OrderDetailsSingleHeader'>
        <div className='OrderDetailsSingleHeaderInner'>
          <Link to={`/Orders`}>
            <img src={Back} className='BackImageIndetails' style={{ "marginRight": "16px", "marginLeft": "8px" }} />
          </Link>
          {Data ? Data[0].username.length>14? Data[0].username.slice(0, 15)+'...':Data[0].username: ""}
        </div>

      </div>
      <div className='OrderDetailsSingleContent'>
        <div className='ContentSingleItem'>
          <div className='ContentSingleItemRow'>
            <div className='ContentSingleItemRowKey'>Status</div>


            {Data ?
              Data[0].status == "done" ?
                <div className='DoneSingle'>
                  {/* {Data[0].status} */}
                  Done
                  <img src={DoneIcon} className='DoneSingleImg' />

                </div>
                :
                Data[0].status == "stop" ?
                  <div className='StopSingle'>
                    {/* {Data[0].status} */}
                    Stop
                    <img src={StopIcon} className='StopSingleImg' />
                  </div>
                  :
                  Data[0].status == "report" ?
                    <div className='ReportSingle'>
                      {/* {Data[0].status} */}
                      Reported
                      <img src={ReportIcon} className='ReportSingleImg' />

                    </div>
                    :
                    Data[0].status == "inprocess" ?
                      <div className='InProgressSingle' onClick={() => OpenBottomSheetInProccess()}>
                        {/* {Data[0].status} */}
                        In process
                        <img src={ProsessingIcon} className='InProgressSingleImg' />

                      </div>
                      : null
              :
              // 1708031565293
              // 1708031565293
              ""
            }


          </div>
          {

            Data ? Data[0].status == "stop" ?
              <div className='ContentSingleItemRow'>
                <div className='ContentSingleItemRowKey'>Reason for stopping</div>
                <div className='ContentSingleItemRowValue ' id="BlueViewText" onClick={() => CalculateReason()}>
                  View
                </div>
              </div> : null : null
          }



          <div className='ContentSingleItemRow'>
            <div className='ContentSingleItemRowKey'>Requested</div>
            <div className='ContentSingleItemRowValue'>
              {Data ? Data[0].count.toLocaleString() : ""}
              {Data ? Data[0].orderType == "member" ? <PersonIcon className='OrdersPersonIcon' /> : <VisibilityIcon className='OrdersPersonIcon' /> : null}
            </div>
          </div>

          <div className='ContentSingleItemRow'>
            <div className='ContentSingleItemRowKey'>Received</div>
            <div className='ContentSingleItemRowValue'>
              {Data ? Data[0].received.toLocaleString() : ""}
              {Data ? Data[0].orderType == "member" ? <PersonIcon className='OrdersPersonIcon' /> : <VisibilityIcon className='OrdersPersonIcon' /> : null}

            </div>
          </div>
          {
            Data ? Data[0].orderType == "view" ?
              <div className='ContentSingleItemRow'>
                <div className='ContentSingleItemRowKey'>Post count</div>
                <div className='ContentSingleItemRowValue'>
                  {Data ? Data[0].postCount.toLocaleString() : ""}</div>
              </div>
              : null
              : null
          }
          {
            Data ? Data[0].postLink ?
              <div className='ContentSingleItemRow'>
                <div className='ContentSingleItemRowKey'>
                  {
                    Data[0].postCount == 1 ?
                      'Post link'
                      :
                      'Username'
                  }
                </div>
                <div className='ContentSingleItemRowValue'>
                  {Data ? Data[0].postLink.length>14? Data[0].postLink.replace("https://t.me/","").slice(0, 15)+'...':Data[0].postLink.replace("https://t.me/","") : ""}</div>
                  
              </div>
              : null
              : null
          }

          {
            Data ? Data[0].orderType == "member" ?
              <div className='ContentSingleItemRow'>
                <div className='ContentSingleItemRowKey'>Left ( 7 days )</div>
                <div className='ContentSingleItemRowValue'>
                  {Data ? Data[0].left : ""}</div>
              </div>
              : null
              : null
          }
          {
            Data ? Data[0].countrymode != null ?
              <div className='ContentSingleItemRow'>
                <div className='ContentSingleItemRowKey'>Country</div>
                <div className='ContentSingleItemRowValue'>
                  {Data ? Data[0].countrymode.name : ""}
                  {Data ? <img src={Data[0].countrymode.flag} style={{ 'width': '16px', 'height': '16px' }} /> : ""}
                  {/* {item.countrymode.flag} */}
                </div>
              </div>
              : null
              : null

          }

          <div className='ContentSingleItemRow'>
            <div className='ContentSingleItemRowKey'>Start time</div>
            <div className='ContentSingleItemRowValue'>
              {Data ?
                // Data[0].date
                moment(Data[0].date).calendar(null, {
                  sameDay: '[Today],HH :MM',
                  nextDay: '[Tomorrow]',
                  nextWeek: 'dddd',
                  lastDay: '[Yesterday],HH : MM',
                  // lastWeek: '[Last] dddd',
                  lastWeek: 'DD MMM , YYYY',
                  sameElse: 'DD MMM , YYYY'
                })
                : ""}</div>
          </div>
          {
            Data ?
              Data[0].finishDateTime ?
                <div className='ContentSingleItemRow'>
                  <div className='ContentSingleItemRowKey'>End time</div>
                  <div className='ContentSingleItemRowValue'>

                    {

                      // Data[0].finishDateTime
                      moment(Data[0].finishDateTime).calendar(null, {
                        sameDay: '[Today] , HH : MM',
                        nextDay: '[Tomorrow]',
                        nextWeek: 'dddd',
                        lastDay: '[Yesterday] , HH : MM',
                        // lastWeek: '[Last] dddd',
                        lastWeek: 'DD MMM , YYYY',
                        sameElse: 'DD MMM , YYYY'
                      })

                    }
                    {/* {Data[0].date} */}
                  </div>
                </div>
                :
                null
              : ""
          }


          <div className='ContentSingleItemRow'>
            <div className='ContentSingleItemRowKey'>Order tracking</div>
            <div className='ContentSingleItemRowValue BlueOrderTracking' onClick={(e) => copyText(ID)}>

              {
                ID.length > 8 ?
                  ID.substring(0, 8) + '...' :
                  ID
              }
              <ContentCopyIcon className='CopyIDOrder' />
            </div>
          </div>
        </div>


        <div className='ShowAllOrders'>
          <Link className='ShowAllOrdersButton' to={`/OrderDetailsAll?username=${Data ? Data[0].username : null}&id=${ID}`}>
            Show all orders
          </Link>
        </div>
        <div className='WarningOrders'>
          <div className='WarningOrdersTitle'>
            Warning
          </div>
          <div className='WarningOrdersBody'>
            <ul className='WarningOrdersUl'>
              <li>
                Do not change ID
              </li>
              <li>
                Do not change channel or group from public to private mode
              </li>
              <li>
                Do not enable "Apply to Join"
              </li>
              <li>
                Changing the ID has a negative effect on the left calculation
              </li>
            </ul>
          </div>
        </div>


      </div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={opens}
        onClose={handleClose}
        // message={` Password changed`}
        key={vertical + horizontal}
        autoHideDuration={1000}
        message={<span id="client-snackbar">
          {/* <CheckCircleIcon className='CheckIcon'/> */}
          Order tracking has been copied

        </span>}

      />


      <BottomSheet onDismiss={onDismiss} open={StatusBottomSheet}>
        <div className='OrdersStatusBottomSheet'>
          <div className='OrdersStatusBottomSheetHeader'>
            Order is processing ...
          </div>
          <div className='ProcessingBottomSheet'>
            <div>
              Until the status of orders is "completed",
            </div>
            <div>
              Avoid doing these things. Otherwise your order will be canceled and the fee will not be refunded:
            </div>

            <ul>
              <li>
                Change channel type or group from public to private mode
              </li>
              <li>
                Change the address (ID) of the channel or group
              </li>
              <li>
                Turn on approve new members in group
              </li>
            </ul>

            <button className='ProcessingBottomSheetButton' onClick={() => onDismiss()}>OK</button>
          </div>
        </div>
      </BottomSheet >


      <BottomSheet onDismiss={onDismiss1} open={StopBottomSheet}>
        <div className='OrdersStatusBottomSheet'>
          <div className='OrdersStatusBottomSheetHeader'>
            Reason for stopping
          </div>
          <div className='ProcessingBottomSheet'>
            {
              ReasonDes ?
                <div className='ItemInBottomSheetStop'>
                  {ReasonDes}
                </div>
                :
                <ul>
                  {
                    ReasonStop.map((item, i) =>
                      <li className='ItemInBottomSheetStop' key={i}>
                        {item.discription_en}
                      </li>
                    )
                  }

                </ul>
            }





            <button className='ProcessingBottomSheetButton' onClick={() => onDismiss1()}>OK</button>
          </div>
        </div>
      </BottomSheet >
    </div>
  )
}

export default OrderDetailsSingle