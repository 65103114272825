import React, { useState } from 'react';
import axios from 'axios';

const PhotoUpload = () => {
    const [file, setFile] = useState(null); // Store the selected file
    const [preview, setPreview] = useState(''); // Store the preview URL
    const [uploadStatus, setUploadStatus] = useState(''); // Upload status

    // Handle file selection
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);

            // Generate a preview URL
            const previewUrl = URL.createObjectURL(selectedFile);
            setPreview(previewUrl);
        }
    };

    // Handle file upload
    const handleUpload = async () => {
        if (!file) {
            alert('Please select a file first!');
            return;
        }

        const formData = new FormData();
        formData.append('photo', file); // Add file to the form data

        try {
            setUploadStatus('Uploading...');
            // Replace 'your-server-endpoint' with your backend endpoint
            const response = await axios.post('your-server-endpoint', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            setUploadStatus('Upload successful!');
   
        } catch (error) {
            setUploadStatus('Upload failed.');
            console.error('Error uploading file:', error);
        }
    };

    return (
        <div style={{ padding: '20px', maxWidth: '400px', margin: 'auto', textAlign: 'center' }}>
            <h2>Upload Photo</h2>
            <input type="file" accept="image/*" onChange={handleFileChange} />
            {preview && (
                <div style={{ marginTop: '10px' }}>
                    <img src={preview} alt="Preview" style={{ width: '100%', maxHeight: '200px', objectFit: 'contain' }} />
                </div>
            )}
            <button onClick={handleUpload} style={{ marginTop: '10px', padding: '10px 20px' }}>
                Upload
            </button>
            {uploadStatus && <p>{uploadStatus}</p>}
        </div>
    );
};

export default PhotoUpload;
