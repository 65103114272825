import React from 'react'
import Slider from './Slider'
import RegisterButton from './RegisterButton'
import LoginButton from './LoginButton'
import LoginGoogle from './LoginGoogle'
import './Auth.css'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { GetCookie } from './../Action/GetCookie'
import { SetCookie } from './../Action/SetCookie'
// import { db } from './../DataBase/db'
// import { useLiveQuery } from "dexie-react-hooks";
import { setLoading } from './../app/Home'
import Loading from './../Loading'
import { useSelector, useDispatch } from 'react-redux'
// import axios from "axios";
// import WebApp from '@twa-dev/sdk'
import { useLocation } from 'react-router-dom';


const Auth = () => {
    const history = useHistory();
    const LoadingState = useSelector((state) => state.Home.Loading)
    const dispatch = useDispatch()

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const ClientUser = searchParams.get('client');
    const Numbersgram = searchParams.get('bot');
    // alert(location.search)
    if (window.Telegram) {
     
        window.Telegram.WebApp.onEvent('viewportChanged', testhandler)
        function testhandler(object) {
            if (!this.isExpanded) {
                this.expand()
            }
        }
    }

    //   window.addEventListener("touchmove", (e) => e.preventDefault(), { passive: false });
    //         window.scrollTo(0, 100);
    useEffect(() => {
        // alert(Numbersgram)
 
        // window.Telegram.WebApp.ready();
        // if (window.Telegram.WebApp != undefined) {
        //          window.Telegram.WebApp.BackButton.show()
        //     //      window.Telegram.WebApp.ready()
        //     //   window.Telegram.WebApp.SettingsButton.show();  

        //     // window.Telegram.WebView.onEvent('backButtonClicked',()=> window.history.back())
        //     // window.Telegram.WebApp.BackButton.onClick(window.history.back())
        if (window.Telegram) {
            window.Telegram.WebApp.expand();
        }
      
        //     // window.Telegram.WebApp.enableClosingConfirmation(); 
        //     window.Telegram.WebApp.BackButton.onClick(() => {
        //         //   window.Telegram.WebApp.sendData(selectedRegions);
        //         // alert("main button clicked");
        //         window.history.back()
        //         // window.Telegram.WebApp.close()
        //     });


        //     // window.Telegram.WebApp.openLink("https://www.googleapis.com/oauth2/v3/userinfo");
        //     // openLink
        //     // alert( window.Telegram.WebApp.platform)
        //     // alert( window.Telegram.WebView.isIframe)

        //     // window.Telegram.WebView.onEvent((e)=>{
        //     //    })  9945543446
        // }

        dispatch(setLoading(true))
        let Token = GetCookie('Token');
        let active = GetCookie('active');

        if (Token !== null) {
            if (active === 'true') {
                if(Numbersgram=='Numbersgram'){
                history.push(`/Coin?Status=Sale`);

                }else{
                  history.push(`/Home`);  
                }
                
            }
        }
        if (ClientUser != null) {
            SetCookie('ClientUser', ClientUser, 100);

        }
        if (Numbersgram != null) {
            SetCookie('Numbersgram', Numbersgram, 100);

        }
        setTimeout(() => {
            dispatch(setLoading(false))
        }, 500);



    }, [])



    return (
        <div className={window.Telegram.WebApp.platform != 'unknown' ? "AuthContainerBottomSpace" : 'AuthContainer'}>
            {LoadingState ?
                <Loading />
                :
                <>
                    <Slider />
                    <div>

                        <RegisterButton />
                        <LoginButton />
                        {window.Telegram.WebApp.platform != 'unknown' ?
                            null
                            :
                            <LoginGoogle />
                        }

                    </div>
                </>

            }

        </div>
    )
}

export default Auth