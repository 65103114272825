import React from 'react'
import { Link } from 'react-router-dom';
import Back from './../Public/Image/BuyCoin/Back.svg'
import EmailIcon from '@mui/icons-material/Email';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PasswordIcon from '@mui/icons-material/Password';
import { AllApi } from './../Api/AllApi'
import { useState, useEffect } from 'react';
import { db } from './../DataBase/db'
import { useLiveQuery } from "dexie-react-hooks";
import { GetCookie } from './../Action/GetCookie'
import { useSelector, useDispatch } from 'react-redux'
import { SetEmail } from './../app/Slice'
import { SetNameAccount } from './../app/Slice'
import { SetPicture } from './../app/Slice'
import { phone } from 'phone';
import { useHistory } from 'react-router-dom';
import Loading from './../Loading'
import Skeleton from '@mui/material/Skeleton';
import { BottomSheet } from 'react-spring-bottom-sheet'
import EditIcon from '@mui/icons-material/Edit';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Snackbar from '@mui/material/Snackbar';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import './EditProfile.css'
import { Troubleshoot } from '@mui/icons-material';

const EditProfile = () => {
  const Token = GetCookie('Token');
  const dispatch = useDispatch()
  const Email = useSelector((state) => state.counter.email)
  const NameAccount = useSelector((state) => state.counter.NameAccount)
  const Picture = useSelector((state) => state.counter.Picture)
  const [PhoneNumber, setPhoneNumber] = useState(null)
  const history = useHistory();
  const [LoadState, setLoadState] = useState(false)
  const [PicFromApi, setPicFromApi] = useState('')
  const GetProfileFunction = async () => {
    setLoadState(true)
    const MakeApiClass = new AllApi();
    let res = await MakeApiClass.GetProfile(Token)

    if (res.code == 200) {
      dispatch(SetEmail(res.data.user.email))
      dispatch(SetNameAccount(res.data.user.nameAccount))

      if (res.data.user.phonenumber) {

        setPhoneNumber(phone(res.data.user.phonenumber))
      } else {


        setPhoneNumber(false)

      }


      dispatch(SetEmail(res.data.user.email))
      let res1 = db.Data.where({ id: Number(1) }).modify({ Email: res.data.user.email })


    } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
      let name = "Token"
      let name1 = "active"
      document.cookie = name + '=; Max-Age=-99999999;';
      document.cookie = name1 + '=; Max-Age=-99999999;';
      history.push("/");


    }
    setLoadState(false)

  }

  useEffect(() => {
    GetProfileFunction()
  }, [])

  const [SetPasswordBool, setSetPasswordBool] = useState(null)

  const GetProfileFunction1 = async () => {
    // let res = await GetProfile(Token)
    const MakeApiClass = new AllApi();
    let res = await MakeApiClass.GetProfile(Token)
   
    if (res.code == 200) {
      setPicFromApi(res.data.user.picture)


      if (res.data.user.setPassword == true) {
     
        setSetPasswordBool(true)

      } else {
     
        setSetPasswordBool(false)



      }


    } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
      let name = "Token"
      let name1 = "active"
      document.cookie = name + '=; Max-Age=-99999999;';
      document.cookie = name1 + '=; Max-Age=-99999999;';
      history.push("/");
      // dispatch(setLoading(false))

    }

  }

  useEffect(() => {
    GetProfileFunction1()
  }, [])

  const [BottomSheetChange, setBottomSheetChange] = useState(false)
  const ShowBottomSheet = () => {
    setBottomSheetChange(true)

  }
  function onDismiss() {
    setTimeout(() => {
      // dispatch(SetCongratulationsBottomSheet(false))
      setBottomSheetChange(false)
    }, 300);

  }



  //  upload image 
  const [file, setFile] = useState(null); // Store the selected file
  const [preview, setPreview] = useState(''); // Store the preview URL
  const [uploadStatus, setUploadStatus] = useState(false); // Upload status
  // Handle file selection
  const handleFileChange = (e) => {
    setBottomSheetChange(false)
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setUploadStatus('Uploading');
      setFile(selectedFile);

      if (selectedFile.type == "image/png" || selectedFile.type == "image/jpg" || selectedFile.type == "image/jpeg" || selectedFile.type == "image/jpg" || selectedFile.type == "image/png" || selectedFile.type == "image/webp") {
        if (selectedFile.size > 500000) {
          // if (selectedFile.size > 7000000) {


          compressImage(selectedFile, 1, function (compressedFile) {
         


            // const downloadLink = document.getElementById('ProfileHeaderImg');

            // downloadLink.href = URL.createObjectURL(compressedFile);
            // downloadLink.download = `compressed_${selectedFile.name}`;

            // downloadLink.src = downloadLink.href;

            handleUpload(compressedFile)

            // document.body.appendChild(downloadLink);
          });



          // Generate a preview URL
          const previewUrl = URL.createObjectURL(selectedFile);
          setPreview(previewUrl);
          // handleUpload(selectedFile)
        } else {
          handleUpload(selectedFile)

        }
      } else {
     
        handleClick({ vertical: 'bottom', horizontal: 'center' })
        setUploadStatus(false);

      }

    }
  };

  // compress iamge
  function compressImage(file, maxFileSizeKB, callback) {
    const reader = new FileReader();

    reader.onload = function (event) {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // تنظیم ابعاد کانواس
        const width = img.width;
        const height = img.height;
        canvas.width = width;
        canvas.height = height;

        // رسم تصویر در کانواس
        ctx.drawImage(img, 0, 0, width, height);

        // کاهش کیفیت تصویر
        let quality = 0.7; // شروع کیفیت از 70%
        let compressedDataUrl = canvas.toDataURL('image/jpeg', quality);

        while (compressedDataUrl.length / 512 > maxFileSizeKB && quality > 0.1) {
          quality -= 0.1;
          compressedDataUrl = canvas.toDataURL('image/jpeg', quality);
        }

        // تبدیل Data URL به Blob
        const compressedBlob = dataURLToBlob(compressedDataUrl);
        const compressedFile = new File([compressedBlob], file.name, {
          type: 'image/jpeg',
          lastModified: Date.now(),
        });

        // بازگشت فایل فشرده
        callback(compressedFile);
      };

      img.src = event.target.result;
    };

    reader.readAsDataURL(file);
  }
  // تبدیل Data URL به Blob
  function dataURLToBlob(dataUrl) {
    const parts = dataUrl.split(',');
    const byteString = atob(parts[1]);
    const mimeString = parts[0].split(':')[1].split(';')[0];

    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
  }



  const [state, setState] = React.useState({
    opens: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, opens } = state;
  const handleClick = (newState) => {
    setState({ ...newState, opens: true });
  };
  const handleClose = () => {
    setState({ ...state, opens: false });
  };
  // Handle file upload
  const handleUpload = async (file) => {
    setUploadStatus('Uploading');

    if (!file) {
      alert('Please select a file first!');
      return;
    }


    try {
 
      const MakeApiClass = new AllApi();
      let res = await MakeApiClass.UploadPictureProfile(file)
   
      if (res.status == 200) {

        if (res.data.code == 200) {
          setPicFromApi(res.data.data.picture)
        } else if (res.data.code == 301) {
      
          handleClick1()


        } else if (res.data.code == 302) {
    
          handleClick({ vertical: 'bottom', horizontal: 'center' })
        }
        else if (res.data.code == 303) {
     

        } else if (res.data.code == 400) {
          handleClick1()

        }
      }else{
      handleClick1()

      }
      setUploadStatus(true);

    } catch (error) {
      setUploadStatus(false);
      console.error('Error uploading file:', error);
    }
  };
  useEffect(() => {
  

    // return () => {

    // }
  }, [uploadStatus])





  const [ConfirmRemoveBottomSheet, setConfirmRemoveBottomSheet] = useState(false)
  function onDismiss1() {
    setTimeout(() => {
      // dispatch(SetCongratulationsBottomSheet(false))
      setConfirmRemoveBottomSheet(false)
    }, 300);

  }

  const ConfirmRemovePhoto = async () => {
    const MakeApiClass = new AllApi();
    let res = await MakeApiClass.RemovePictureProfile(file)

    
    if (res.status == 302) {
      handleClick1()
      setConfirmRemoveBottomSheet(false)

    } else if (res.status == 200) {
      setPicFromApi("")
      setConfirmRemoveBottomSheet(false)

    } else {
      handleClick1()
      setConfirmRemoveBottomSheet(false)

    }
  }


  // const [stateNew, setStateNew] = React.useState({
  //   opensNew: false,
  //   verticalNew: 'top',
  //   horizontalNew: 'center',
  // });
  // const { verticalNew, horizontalNew, opensNew } = stateNew;
  // const handleClickNew = (newState) => {
  //   setStateNew({ ...newState, opensNew: true });
  // };
  // const handleCloseNew = () => {
  //   setStateNew({ ...stateNew, opensNew: false });
  // };

  const [open1, setOpen1] = React.useState(false);

  const handleClick1 = () => {
    setOpen1(true);
  };

  const handleClose1 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen1(false);
  };
  return (
    LoadState || SetPasswordBool == null ?
      <Loading />
      :

      <div className='AccountInformationContainer'>
        <div className='TransferHistoryHeader'>
          <Link to="/Profile">
            <img src={Back} className='TransferHistoryHeaderImg' />
          </Link>
          <div className='TransferHistoryHeaderText'>Edit Profile</div>
        </div>
        <div className='AccountInformationBody'>
          <div className="EditPictureContainer">

            {/* { uploadStatus == 'Uploading' ?
               "Uploading"
            : 
            null} */}
            {
              PicFromApi ?
                PhoneNumber == null || uploadStatus == 'Uploading' ?
                  <Skeleton variant="circular" width={64} height={64} animation="wave" />
                  :
                  <img className="ProfileHeaderImg" id="ProfileHeaderImg" src={PicFromApi} style={{ "width": "64px", "height": "64px" }} />
                :
                <div className='ProfilePhotoTextContainer'>
                  {
                    PhoneNumber == null ?
                      <Skeleton variant="circular" width={64} height={64} animation="wave" />
                      : <span className='ProfilePhotoText AccountText' style={{ "width": "48px", "height": "48px" }}>

                        {Email.charAt(0).toUpperCase()}
                      </span>
                  }

                </div>
            }
            <span className='EditPictureLink' onClick={() => ShowBottomSheet()}>
              Edit picture
            </span>
          </div>

          <div className='AccountInformationItemEdit' style={{ 'marginBottom': '8px',"marginTop":"16px" }}>
            <div className='AccInfoTitleEditProfile'><EmailOutlinedIcon className='EditProfileIcons' /> Email</div>
            <div className='AccInfoValueEditProfile' style={{ 'color': '#212121' }}>{


              Email.length > 20 ?
                Email.substring(0, 20) + '...' :
                Email

            }</div>
          </div>
          {PhoneNumber != null && PhoneNumber != false ?
            <Link to={`/EditNumber`} className='AccountInformationItemEdit' style={{ 'marginBottom': '8px' }}>
              <div className='AccInfoTitleEditProfile'><SmartphoneOutlinedIcon className='EditProfileIcons' />Phone number</div>
              <div className='AccInfoValueEditProfile'> {PhoneNumber ? PhoneNumber.phoneNumber : null}</div>
            </Link>
            :
            null
          }

          <Link to={`/SetName`} className='AccountInformationItemEdit' style={{ 'marginBottom': '8px' }}>
            <div className='AccInfoTitleEditProfile'><PersonOutlineOutlinedIcon className='EditProfileIcons' />Name</div>
            <div className='AccInfoValueEditProfile'>{NameAccount}</div>
          </Link>

          {SetPasswordBool == false ?
            <Link to={`/SetPassword`} className='AccountInformationItemEdit' style={{ 'marginBottom': '8px' }}>
              <div className='AccInfoTitleEditProfile'><PasswordIcon className='EditProfileIcons' />Password</div>
              <div className='AccInfoValueEditProfile'>Set</div>
            </Link>

            :
            <Link to={`/GetCheckPassword`} className='AccountInformationItemEdit' style={{ 'marginBottom': '8px' }}>
              <div className='AccInfoTitleEditProfile'><PasswordIcon className='EditProfileIcons' />Password</div>
              <div className='AccInfoValueEditProfile'>Change</div>
            </Link>
          }



        </div>





        <BottomSheet onDismiss={onDismiss} open={BottomSheetChange} >
          <div className='BottomSheetChange'>
            <div className='ChangeBottomSheetPic'>
              <input type="file" accept="image/*" onChange={handleFileChange} className='InputHiddenFile' />
              <ModeEditOutlinedIcon className='IconChangeBottomSheetPic' />     change


            </div>
            <div className='ChangeBottomSheetPic' onClick={() => { setConfirmRemoveBottomSheet(true); onDismiss() }}>
              <DeleteOutlineRoundedIcon className='IconChangeBottomSheetPic' />    Remove
            </div>
          </div>
        </BottomSheet>


        <BottomSheet onDismiss={onDismiss1} open={ConfirmRemoveBottomSheet} >
          <div className='BottomSheetRemoveProfile'>
            <div className='BottomSheetRemoveProfilePic'>

              <ReportProblemIcon className='RemoveProfilePicIcon' style={{ width: "24px", height: "24px" }} /> Remove photo


            </div>
            <div className='BottomSheetRemoveProfilePicDescription'>
              Are you sure you want remove your photo?
            </div>
            <div className='BottomSheetRemoveProfilePicDescription'>
              <button className='CancelRemovePhoto' onClick={() => onDismiss1()}>Cancel</button>
              <button className='SubmitRemovePhoto' onClick={() => ConfirmRemovePhoto()}>Remove</button>
            </div>

          </div>
        </BottomSheet>


        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={opens}
          onClose={handleClose}
          // message={` Password changed`}
          key={vertical + horizontal}
          autoHideDuration={3000}
          message={<span id="client-snackbar"><ReportProblemIcon className='CheckIcon' style={{ color: "FFF" }} /> Allowed formats : png , jpg , jpeg , JPG , PNG , webp</span>}

        />
<Snackbar
  open={open1}
  autoHideDuration={6000}
  onClose={handleClose}
  message="Something went wrong!"
  // action={action}
/>


        {/* <Snackbar
          anchorOrigin={{ verticalNew, horizontalNew }}
          open={opensNew}
          onClose={handleCloseNew}
          // message={` Password changed`}
          key={verticalNew + horizontalNew}
          autoHideDuration={3000}
          message={<span id="client-snackbar"> Something went wrong! </span>}

        /> */}
      </div>
  )
}

export default EditProfile