import React from 'react'
import { Link } from 'react-router-dom';
import Back from './../Public/Image/BuyCoin/Back.svg'
import './AccountInformation.css'
import './SetPassword.css'

import { AllApi } from './../Api/AllApi'
import { GetCookie } from '../Action/GetCookie'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import Loading from './../Loading'


const Privacy = () => {

    const history = useHistory();

    const Token = GetCookie('Token');
    const [SetPasswordBool, setSetPasswordBool] = useState(null)
    const GetProfileFunction = async () => {
        // let res = await GetProfile(Token)
        const MakeApiClass = new AllApi();
        let res = await MakeApiClass.GetProfile(Token)
   
        if (res.code == 200) {


            if (res.data.user.setPassword == true) {
         
                setSetPasswordBool(true)

            } else {
           
                setSetPasswordBool(false)



            }


        } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

        }

    }

    useEffect(() => {
        GetProfileFunction()
    }, [])
    const GetSetPassword = () => {
        history.push("/SetPassword");
    }
    const GetChangePassword = () => {
        history.push("/GetCheckPassword");
    }
    return (
        <>
          {
        SetPasswordBool==null?
        <Loading />

        :    <div className='AccountInformationContainer'>
            <div className='TransferHistoryHeader'>
                <Link to="/Profile">
                    <img src={Back} className='TransferHistoryHeaderImg' />
                </Link>
                <div className='TransferHistoryHeaderText'>Privacy and security</div>
            </div>

            <div className='AccountInformationBody' 
            style={{  'paddingBottom': '1rem' }}
            >



                {
                // SetPasswordBool == false ?

                //     <div className='SetPasswordContainer' onClick={() => GetSetPassword()}>
                //         <div className='SetPasswordTitle' >
                //             Set Password
                //         </div>
                //         <div className='SetPasswordDescription'>
                //             Set a password for your account
                //         </div>
                //     </div>

                //     :
                //     SetPasswordBool == true ?
                //         <div className='SetPasswordContainer' onClick={() => GetChangePassword()}>
                //             <div className='SetPasswordTitle' >
                //                 Change Password
                //             </div>
                //             <div className='SetPasswordDescription'>
                //                 Change a password for your account
                //             </div>

                //         </div>
                //         :
                //         <Loading />

                }






            </div>
            <div className='AccountInformationBody' style={{ 'marginTop': '8px','marginLeft':'.5rem' }}>
                 <a href="https://membersgram.com/tos/"  className='SetPasswordContainer' >
                    <div className='SetPasswordTitle' >
                        Terms of Service
                    </div>
                </a>

            </div>
            <div className='AccountInformationBody' style={{ 'marginTop': '1px','marginLeft':'.5rem' }}>
                <a href="https://membersgram.com/privacy/" className='SetPasswordContainer' >
                    <div className='SetPasswordTitle' >
                    Privacy policies
                    </div>
                </a>

            </div>

          
        </div>
       } 
        </>
     
    
    )
}

export default Privacy