import React from 'react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { GetCookie } from './../Action/GetCookie'
import BottomNavigation from './BottomNavigation';
import Header from './Header';
import './Home.css'
import MemberPackageItem from './MemberPackageItem'
import MemberPackageItemZarinPall from './MemberPackageItemZarinPall'
import MemberPackageCoinItem from './MemberPackageCoinItem'
import HomeTabs from './HomeTabs';
import Countries from './Countries'
import Instantstart from './../Public/Image/Home/Instantstart.svg'
import Bestquality from './../Public/Image/Home/Bestquality.svg'
import Ongoingsupport from './../Public/Image/Home/Ongoingsupport.svg'
import { useSelector, useDispatch } from 'react-redux'
import PaymentError from './PaymentError'
import ViewPackItem from './ViewPackItem'
import ViewPackItemZarinPall from './ViewPackItemZarinPall'
import Loading from './../Loading'
import PostViewFilter from './PostViewFilter'
import UnsuccessfulPayment from '../BottomSheet/UnsuccessfulPayment';
import SuccessfulPayment from '../BottomSheet/SuccessfulPayment';
import { setBottomSheetUnsuccessfulPayment } from './../app/Home'
import { setBottomSheetsuccessfulPayment } from './../app/Home'
import ViewPackItemCoin from './ViewPackItemCoin'
import { useLocation } from 'react-router-dom';
import { SetEmail } from './../app/Slice'
import { AllApi } from './../Api/AllApi'
import { setUserFromApi } from './../app/Home'
import { setIsIranian } from './../app/Home'
import { SetPackageData } from './../app/Home'
import { setViewPackageData } from './../app/Home'
import { setLoading } from './../app/Home'
import { setUserCoin } from './../app/Home'
import { UpdateCheck } from './../Action/UpdateCheck'
import { SetUpdate } from './../app/Home'
import { SetUpdateVersion } from './../app/Home'
import { SetTab } from './../app/Home'

const Home = () => {














  const Token = GetCookie('Token')
  const dispatch = useDispatch()
  const LoadingState = useSelector((state) => state.Home.Loading)
  const history = useHistory();
  let AuthState = GetCookie('Token')
  const Tab = useSelector((state) => state.Home.Tab)
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const PaymentReturn = searchParams.get('payment');
  const PaymentReturnView = searchParams.get('paymentview');
  const Point = searchParams.get('point');

  const [PaymentErrorState, setPaymentErrorState] = useState(false)
  const [Data, setData] = useState([])
  const PackageData = useSelector((state) => state.Home.PackageData)
  const UserFromApi = useSelector((state) => state.Home.UserFromApi)
  const IsIranian = useSelector((state) => state.Home.IsIranian)
  const GetViewFunc = async (status) => {
    dispatch(setLoading(true))
    const MakeApiClass = new AllApi();
    let res = await MakeApiClass.GetView(status)

    if (res.code == 200) {
      dispatch(setViewPackageData(res.data.data))
      dispatch(setLoading(false))

    } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
      let name = "Token"
      let name1 = "active"
      document.cookie = name + '=; Max-Age=-99999999;';
      document.cookie = name1 + '=; Max-Age=-99999999;';
      history.push("/");

    } else {
      dispatch(setLoading(true))
    }

  }
  const GetMemberFunc = async (status) => {
    dispatch(setLoading(true))
    const MakeApiClass = new AllApi();
    let res = await MakeApiClass.GetMember(status)
    // let res = { code: 999 }
 
    if (res.code == 200) {

      dispatch(setUserCoin(res.data.user.coins))
      dispatch(SetPackageData(res.data.data))
      dispatch(setLoading(false))


    }
    else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
      let name = "Token"
      let name1 = "active"
      document.cookie = name + '=; Max-Age=-99999999;';
      document.cookie = name1 + '=; Max-Age=-99999999;';
      history.push("/");
      dispatch(setLoading(false))

    }
    else if (res.code == 999) {
    

      dispatch(setLoading(false))

    }

  }
  const GetProfile1 = async () => {
   
    const MakeApiClass = new AllApi();
    let ProfileRes = await MakeApiClass.GetProfile(Token)
    if (ProfileRes.code == 200) {
      dispatch(setUserFromApi(ProfileRes.data.user))
      if (ProfileRes.data.user) {
        dispatch(SetEmail(ProfileRes.data.email))
        let UserPhone = ProfileRes.data.user.phonenumber;
      
        if (UserPhone) {
          if (UserPhone.length > 0 && UserPhone != undefined && UserPhone != null) {
            if (UserPhone.startsWith("+98")) {
              dispatch(setIsIranian(true))
              GetMemberFunc(true)
              GetViewFunc(true)
            } else {
              dispatch(setIsIranian(false))
              GetMemberFunc(false)
              GetViewFunc(false)


            }

          }
        } else {
          dispatch(setIsIranian(false))
          GetMemberFunc(false)
          GetViewFunc(false)
        }

      }
    }

  }


  useEffect(() => {
   
    dispatch(SetPackageData([]))
    dispatch(setViewPackageData([]))

    // GetData()
    GetProfile1()
  }, [])


  useEffect(() => {
    
  
    if (AuthState == null) {
      history.push(`/`);
    }
    if (PaymentReturn == 'true') {
      dispatch(setBottomSheetsuccessfulPayment(true))
      searchParams.delete('payment');
      searchParams.delete('count');
      searchParams.delete('username');
      // searchParams.set('Status', 'Member');
      searchParams.delete('point');
      // Create a new URL by combining the base URL and the updated search parameters
      const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;

      // Update the browser history without reloading the page
      window.history.replaceState({ path: newUrl }, '', newUrl);
    } else if (PaymentReturn === 'false') {
      searchParams.delete('payment');
      searchParams.delete('count');
      searchParams.delete('username');
      searchParams.delete('point');

      const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState({ path: newUrl }, '', newUrl);

      dispatch(setBottomSheetUnsuccessfulPayment(true))
    }
    if (PaymentReturnView === 'true') {
      dispatch(setBottomSheetsuccessfulPayment(true))
      searchParams.delete('paymentview');
      searchParams.delete('count');
      searchParams.delete('username');
      searchParams.delete('point');

      searchParams.set('Status', 'View');
      dispatch(SetTab('View'))

      // Create a new URL by combining the base URL and the updated search parameters
      const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;

      // Update the browser history without reloading the page
      window.history.replaceState({ path: newUrl }, '', newUrl);
    } else if (PaymentReturnView === 'false') {
      searchParams.delete('paymentview');
      searchParams.delete('count');
      searchParams.delete('username');
      searchParams.delete('point');
      searchParams.set('Status', 'View');
      dispatch(SetTab('View'))

      const newUrl = `${window.location.origin}${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState({ path: newUrl }, '', newUrl);

      dispatch(setBottomSheetUnsuccessfulPayment(true))
    }

  }, [AuthState])
 


  useEffect(() => {
    CheckUpdateAccess()
}, [])

async function CheckUpdateAccess(){
  let UpdateRes=await  UpdateCheck()
 let DissmissedVersion= GetCookie('DissMissUpdateVersion')

 dispatch(SetUpdateVersion(UpdateRes.version))
  if(UpdateRes.status == true && Number(DissmissedVersion) != UpdateRes.version){
    dispatch(SetUpdate(true))

  }
}
  return (
    <div className='Home'>
      <div className='Header'>
        <Header />
      </div>
      <div className='Content'>
        <HomeTabs />
        {
          PaymentErrorState ? <PaymentError /> : null
        }

        {
          Tab === 'Member' ?


            LoadingState || PackageData.length == 0 ?
              <Loading />
              :
              <>
                {
                  PackageData.length > 1 ?
                    <Countries />
                    : null
                }

                <div className='MemberPackage'>
                  <div className={PackageData.length > 1 ? 'MemberPackageTitle' : 'MemberPackageTitleWithoutFlag'}>
                    Get Member
                  </div>
                  <div className='MemberPackageItems'>
                    {
                      IsIranian == undefined ?
                        <Loading />
                        :
                        IsIranian == true ?
                          <MemberPackageItemZarinPall />
                          :
                          <MemberPackageItem />
                    }
                    {/* <MemberPackageItem /> */}


                  </div>
                </div>
                <div className='MemberPackage'>
                  <div className='MemberPackageTitle'>
                    Get Member by coins
                  </div>
                  <div className='MemberPackageItems'>
                    <MemberPackageCoinItem />
                  </div>
                </div>
              </>

            :
            LoadingState ?
              <Loading />
              :
              <>
                <PostViewFilter />
                <div className='ViewPackage'>
                  <div className='ViewPackageTitle'>
                    View order
                  </div>
                  <div className='ViewPackageItems'>
                    {
                      IsIranian == undefined ?
                        <Loading />
                        :
                        IsIranian == true ?
                          <ViewPackItemZarinPall />
                          :
                          <ViewPackItem />
                    }

                    {/* <ViewPackItem /> */}

                  </div>
                </div>
                <div className='MemberPackage'>
                  <div className='MemberPackageTitle'>
                    Order view with coins
                  </div>
                  <div className='MemberPackageItems'>
                    <ViewPackItemCoin />
                  </div>
                </div>
              </>


        }


      </div>
      <div className='FooterContent'>
        <div className='FooterContentTitle'>
          You deserve the best:
        </div>
        <div className='FooterContentItems'>
          <div className='FooterContentItem'>
            <img src={Instantstart} className='FooterContentItemPic' />
            <div>
              Instant start
            </div>
          </div>
          <div className='FooterContentItem Bestquality'>
            <img src={Bestquality} className='FooterContentItemPic' />
            <div>
              Best quality
            </div>
          </div>
          <div className='FooterContentItem'>
            <img src={Ongoingsupport} className='FooterContentItemPic' />
            <div>
              Ongoing support
            </div>
          </div>
        </div>
      </div>
      <div className='NavContainer'>
        <BottomNavigation />
      </div>
      <UnsuccessfulPayment />
      <SuccessfulPayment />

    </div>
  )
}

export default Home