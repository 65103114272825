import React from 'react'
import DoneIcon from '@mui/icons-material/Done';
import { SetPostViewFilter } from './../app/Home'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react';

const PostViewFilter = () => {
    const ViewPackageData = useSelector((state) => state.Home.ViewPackageData)

    const dispatch = useDispatch()
    const PostViewFilterRedux = useSelector((state) => state.Home.PostViewFilter)
    let PostViewFilter = localStorage.getItem('PostViewFilter')

    useEffect(() => {
        if (PostViewFilter) {
            HandlePostViewFilter(Number(PostViewFilter))
        }
        else {
            HandlePostViewFilter(1)
        }
    }, [])
    const HandlePostViewFilter = (value) => {

        localStorage.setItem('PostViewFilter', value)
        dispatch(SetPostViewFilter(value))

    }
    return (
        <div className='CountriesContainer'>
            <div className='Countries'>
                {
                    ViewPackageData.map((item, i) =>
                        <div key={i} className={PostViewFilterRedux === item.count_post ? "CountryItemActive" : "CountryItem"}
                            onClick={() => { HandlePostViewFilter(item.count_post) }}>
                            {PostViewFilterRedux === item.count_post ? <DoneIcon className='Flag' /> : null}
                            {item.count_post} post
                        </div>
                    )
                }

                {/* <div className={PostViewFilterRedux === "5 post" ? "CountryItemActive" : "CountryItem"}
                    onClick={() => { HandlePostViewFilter('5 post') }}>
                    {PostViewFilterRedux === "5 post" ? <DoneIcon className='Flag' /> : null}
                    5 Post
                </div>
                <div className={PostViewFilterRedux === "10 post" ? "CountryItemActive" : "CountryItem"}
                    onClick={() => { HandlePostViewFilter('10 post') }}>
                    {PostViewFilterRedux === "10 post" ? <DoneIcon className='Flag' /> : null}

                    10 Post
                </div>

                <div className={PostViewFilterRedux === "20 post" ? "CountryItemActive" : "CountryItem"}
                    onClick={() => { HandlePostViewFilter('20 post') }}>
                    {PostViewFilterRedux === "20 post" ? <DoneIcon className='Flag' /> : null}

                    20 Post
                </div> */}


            </div>
        </div>
    )
}

export default PostViewFilter