import React from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
// import One from './../../Public/Image/Game/topjoiner/icons8_1st_place_medal_1 1.svg'
// import Two from './../../Public/Image/Game/topjoiner/icons8_2nd_place_medal 1.svg'
// import Three from './../../Public/Image/Game/topjoiner/icons8_3rd_place_medal 1.svg'
import { SetGetNameBottomSheet } from './../../app/Game'
import './GetName.css'
// import Coin from './../../Public/Image/Game/Coin.svg'
// import Star from './../../Public/Image/Game/Star.svg'
import { useSelector, useDispatch } from 'react-redux'
import { AllApi } from '../../Api/AllApi'

const GetName = () => {
    const dispatch = useDispatch()
    const GetNameBottomSheet = useSelector((state) => state.Game.GetNameBottomSheet)
    // const UserNameForOrder = useSelector((state) => state.Home.UserNameForOrder)

    function onDismiss() {
        setTimeout(() => {
            dispatch(SetGetNameBottomSheet(true))
        }, 300);

    }
    const [Name, setName] = useState('')
    const [ErrorName, setErrorName] = useState(false)
    const [ErrorNameEmpty, setErrorNameEmpty] = useState(false)
    const handleName = (name) => {
        setErrorNameEmpty(false)
        setErrorName(false)
        setName(name)
    }
    const SubmitName = async () => {
      
        let name = Name.trim()
        if (name.length > 23) {
            setErrorName(true)
        } else if (name.length < 1) {
            setErrorNameEmpty(true)

        } else {
            const MakeApiClass = new AllApi();
            let nameRes = await MakeApiClass.SetNameAccount(name)
           
            dispatch(SetGetNameBottomSheet(false))
        }

    }

    const sheetRef = React.useRef()

    return (
        <BottomSheet onDismiss={onDismiss}
            open={GetNameBottomSheet}
            ref={sheetRef}
            onSpringStart={() => {
                console.log('Transition from:', sheetRef.current.height)
                requestAnimationFrame(() =>
                    console.log('Transition to:', sheetRef.current.height)
                )
            }}
            onSpringEnd={() =>
                console.log('Finished transition to:', sheetRef.current.height)
            }

        >
            <div className='CongratulationsBottomSheet'>
                <div className='CongratulationsBottomSheetHeader'>
                    <div className='CongratulationsBottomSheetHeaderText'>Set Name</div>
                </div>
                <div className='CongratulationsBottomSheetBody'>
                    Please set a name for your account
                    <div className='CongratulationsBottomSheetBodyReward'>
                        {
                            ErrorNameEmpty ?
                                <TextField
                                    error
                                    fullWidth
                                    variant="outlined"
                                    className='RegisterPasswordTextField'
                                    label="Name"
                                    id="outlined-error-helper-text"
                                    helperText="Please set a name for your account"
                                    type='text'
                                    value={Name}
                                    onChange={(e) => handleName(e.target.value)}
                                    autoFocus
                                />
                                :
                                ErrorName ?
                                    <TextField
                                        error
                                        fullWidth
                                        variant="outlined"
                                        className='RegisterPasswordTextField'
                                        label="Name"
                                        id="outlined-error-helper-text"
                                        helperText="The maximum number of characters is 24"
                                        type='text'
                                        value={Name}
                                        onChange={(e) => handleName(e.target.value)}
                                        autoFocus
                                    />
                                    :
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        variant="outlined"
                                        className='RegisterPasswordTextField'
                                        label="Name"
                                        id="Name"
                                        value={Name}
                                        onChange={(e) => handleName(e.target.value)}
                                    />
                        }
                    </div>

                </div>
                <div className='CongratulationsBottomSheetFooter'>

                    <button className='CongratulationsBottomSheetButton' onClick={() => SubmitName()} >Save</button>
                </div>
            </div>
        </BottomSheet>
    )
}

export default GetName 