import React from 'react'
import { Link } from 'react-router-dom';
import Back from './../Public/Image/BuyCoin/Back.svg'
import './AccountInformation.css'
// import {GetProfile} from './../Api/GetProfile'
import { AllApi } from './../Api/AllApi'

import { GetCookie } from '../Action/GetCookie'
import { useEffect,useState } from 'react'
import { useHistory } from 'react-router-dom';

const AccountInformation = () => {
  const history = useHistory();

  useEffect(() => {
    GetProfileFunc()
  }, [])
  const [Data, setData] = useState('')
  const GetProfileFunc=async()=>{
    const Token = GetCookie('Token');

  //  let res=await GetProfile(Token)
  const MakeApiClass = new AllApi();
  let res=await MakeApiClass.GetProfile(Token)
  
   if(res.code==200){
setData(res.data.user)
   } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
                    let name = "Token"
                    let name1 = "active"
                    document.cookie = name + '=; Max-Age=-99999999;';
                    document.cookie = name1 + '=; Max-Age=-99999999;';
                    history.push("/");
                    // dispatch(setLoading(false))

                }
   
  }
  return (
    <div className='AccountInformationContainer'>
      <div className='TransferHistoryHeader'>
        <Link to="/Profile">
          <img src={Back} className='TransferHistoryHeaderImg' />
        </Link>
        <div className='TransferHistoryHeaderText'>Account Information</div>
      </div>
      <div className='AccountInformationBody'>
        <div className='AccountInformationItem'>
          <div className='AccInfoTitle'>My coin count</div>
          <div className='AccInfoValue'>{parseInt(Data.coins).toLocaleString()}</div> 
        </div>
        <div className='AccountInformationItem'>
          <div className='AccInfoTitle'>Compensatory coin for left in my orders (up to a week)</div>
          <div className='AccInfoValue'>{parseInt(Data.added_coins).toLocaleString()}</div>
        </div>
        <div className='AccountInformationItem'>
          <div className='AccInfoTitle'>Deducted coins per left</div>
          <div  className='AccInfoValue'>{parseInt(Data.deducted_coins).toLocaleString()}</div>
        </div>
      </div>
    </div>
  )
}

export default AccountInformation