import React from 'react'
import Person from './../Public/Image/Home/Person.svg'
import './MemberPackageCoinItem.css'
import Coin from './../Public/Image/Home/Coin.svg'
import { GetMember } from './../Api/GetMember'
import { GetCookie } from '../Action/GetCookie'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// import { Link } from 'react-router-dom';
import InsufficientBalance from './../BottomSheet/InsufficientBalance'
import { setInsufficientBalance } from './../app/Home'
import { useHistory } from 'react-router-dom';
import xpplus from './../Public/Image/Join/xpplus.svg'

const MemberPackageCoinItem = () => {
    const Token = GetCookie('Token')
    const PackageData = useSelector((state) => state.Home.PackageData)
    const NoticeChecked = localStorage.getItem('Notice')
    const UserCoin = useSelector((state) => state.Home.UserCoin)
    const dispatch = useDispatch()
    const history = useHistory();
    


    const Countries = useSelector((state) => state.Home.Countries)
    const GetMemberFunc = async () => {
        // let res = await GetMember(Token)

        // dispatch(SetPackageData(res.data.data))

    }
    useEffect(() => {
        if (PackageData.length === 0) {
            // GetMemberFunc()
        }
    }, [])
    const CheckCoinUser = (PackageCoin,MemberCount,_id) => {
        if (UserCoin >= PackageCoin) {
            history.push(NoticeChecked ? `/GetLinkUsername?Price=${PackageCoin}&Count=${MemberCount}&ByCoin=true&_id=${_id}`: `/Notice?Price=${PackageCoin}&Count=${MemberCount}&ByCoin=true&_id=${_id}`);
        } else {
            dispatch(setInsufficientBalance(true))

        }
    }
  
    return (
        <>
            {PackageData.length > 0 ?
                PackageData.map(item =>
                    item.buyCountries.name === Countries ?
                        item.coin_getmember.map((Coinitem, i) =>
                            <div className='PackageCoinItem' key={i} onClick={() => CheckCoinUser(Coinitem.coin,Coinitem.member_count,Coinitem._id)}>
                                <div className='CoinItem'>
                                    <div>
                                        <img src={Person} />
                                    </div>
                                    <div className='CoinItemDigit'>
                                        <div className='CoinItemDigitNewPrice'>
                                            {Coinitem.member_count.toLocaleString()}
                                            {Coinitem.xpCount ?<span className='XpCountInlist'>+ {Coinitem.xpCount} <img src={xpplus} className='XpCountInlistIcon' /> </span>: null}  

                                        </div>

                                    </div>
                                </div>
                                <div className='CoinItem'>


                                    <div >
                                        <div className='CoinItemOrder'>
                                            <button className='CoinItemOrderButton'>
                                                <img src={Coin} className='CoinItemOrderButtonIcon' />  {Coinitem.coin.toLocaleString()}
                                              
                                                </button>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        )
                        : null

                )


                : null}
            <InsufficientBalance />
        </>
    )
}

export default MemberPackageCoinItem