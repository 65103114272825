import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react';
// import Tooltip from '@mui/material/Tooltip';
import HeadImg from './../../src/Public/Image/Game/Star 4.svg'
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
// import { makeStyles } from '@mui/core/styles';
import Loading from './../Loading'


export default function LinearDeterminate() {
    const [progress, setProgress] = React.useState(0);
    const UserLeagueData = useSelector((state) => state.Game.UserLeagueData)
    const LevelUserHeaderProgress = useSelector((state) => state.Game.LevelUserHeaderProgress)

    useEffect(() => {
    
        if (LevelUserHeaderProgress) {
            let Limit = LevelUserHeaderProgress.user.xpLimit
            let Count = LevelUserHeaderProgress.user.xpCount


            let ProgressFinal = (Count * 100) / Limit
         
            setProgress(ProgressFinal)

        }


    }, [LevelUserHeaderProgress])

    // React.useEffect(() => {

    //   const timer = setInterval(() => {
    //     setProgress((oldProgress) => {
    //       if (oldProgress === 100) {
    //         return 0;
    //       }
    //       const diff = Math.random() * 10;
    //       return Math.min(oldProgress + diff, 100);
    //     });
    //   }, 2000);

    //   return () => {
    //     clearInterval(timer);
    //   };
    // }, []);
  

    // const classes = useStyles();
    // const useStyles = makeStyles(theme => ({
    //     customTooltip: {
    //       // I used the rgba color for the standard "secondary" color
    //       backgroundColor: 'rgba(220, 0, 78, 0.8)',
    //     },
    //     customArrow: {
    //       color: 'rgba(220, 0, 78, 0.8)',
    //     },
    //   }));
    function abbreviate_number(num, fixed) {
  
        if (num === null || num == undefined || num == false) { return null; } // terminate early
        if (num === 0) { return '0'; } // terminate early
        fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
        var b = (num).toPrecision(2).split("e"), // get power
            k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
            c = k < 1 ? num.toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
            d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
            e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power

        return e;
    }
    return (
        LevelUserHeaderProgress.user ?
            <div className='ProgressBarInProfileComponent' style={{}}>
                <div className='YourLevel'>
                    Your Level:
                </div>
                <div className='HeaderLogo HeaderProgressLevelPts' style={{ "backgroundColor": "#fff", cursor: "default" }}>

                    <div className='HeaderLogoPtLevel' >

                        <div className='HeaderProgressBarImgContainer'>
                            <div className='HeaderProgressBarAbsoluteNumber'>
                                {LevelUserHeaderProgress.user.xpLevel}

                            </div>
                            <img src={HeadImg} className='HeaderProgressBarImg' style={{ width: "32px", height: "32px", paddingLeft: "3px" }} />
                        </div>

                    </div>
                    <div className='HeaderLogoPt'>
                        <div>

                            <span style={{ 'fontWeight': '700' }}>
                                {abbreviate_number(LevelUserHeaderProgress.user.xpLimit - LevelUserHeaderProgress.user.xpCount)}
                            </span> Pts   To level {LevelUserHeaderProgress.user.xpLevel ? LevelUserHeaderProgress.user.xpLevel + 1 : null}


                        </div>
                        {/* <div>
                         
                        </div> */}
                    </div>
                </div>
                <Box sx={{ width: '100%', height: '16px', marginLeft: '0px', 'rotate': '0deg !important', 'marginRight': '-3px', 'zIndex': '0' }}>




                    <LinearProgress variant="determinate" value={progress}
                        sx={{
                            backgroundColor: 'white',
                            '& .MuiLinearProgress-bar': {
                                backgroundColor: '#1976D2'
                            }
                        }} style={{ 'border': '1px solid #E0E0E0', 'height': '16px', 'backgroundColor': '#F5F5F5', 'color': '#BF8970 !important', "borderRadius":"10px", 'rotate': '180deg !important' }} />

                    <div className='PtsProfileBottom'>
                        {
                            abbreviate_number(LevelUserHeaderProgress.user.xpCount)
                        } pts
                    </div>

                </Box>
                {/* <div className='HeaderProgressBarImgContainer'>
                    <div className='HeaderProgressBarAbsoluteNumber'>
                        {LevelUserHeaderProgress.user.xpLevel}

                    </div>
                    <img src={HeadImg} className='HeaderProgressBarImg' />
                </div> */}
            </div>
            : <Loading />
    );
}
