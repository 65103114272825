import React from 'react'
import { Link } from 'react-router-dom';
import BackImg from './../Public/Image/Login/Back.svg'

import Checked from './../Public/Image/Sale/Checked.svg'
import Pending from './../Public/Image/Sale/Pending.svg'
import Coin from './../Public/Image/Sale/Coin.svg'
import Copy from './../Public/Image/Sale/Copy.svg'
import Banned from './../Public/Image/Sale/Banned.svg'

import HistoryEmpty from './../Public/Image/Sale/HistoryEmpty1.svg'

import { AllApi } from './../Api/AllApi'
import { useEffect } from 'react';
import './SaleHistory.css'
import Snackbar from '@mui/material/Snackbar';
import { useState } from 'react';
// import moment from 'moment';
import moment from 'jalali-moment'

import ReactCountryFlag from "react-country-flag"
import { phone } from 'phone';
import Loading from './../Loading'
import InfiniteScroll from "react-infinite-scroll-component";
// import axios from 'axios';
const SaleHistory = () => {
    useEffect(() => {
        // GetHistory(1)
    }, [])
    // const GetHistory = async (page) => {
    //     const MakeApiClass = new AllApi();
    //     let res = await MakeApiClass.GetHistoryFunc(page)
    //     //  let res=await GetSellPhoneNumber(CountryCode+PhoneNumber)
 
    //     let DataBeforeSend = res.data.data
    //     DataBeforeSend.map(item => {
 
    //         item.PhoneTest = phone(item.phonenumber)
    //     })



    //     setData(DataBeforeSend)
    // }


    const [state, setState] = React.useState({
        opens: false,
        vertical: 'top',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose = () => {
        setState({ ...state, opens: false });
    };
    const copyText = async (value) => {
        try {
            await navigator.clipboard.writeText(value);
            handleClick({ vertical: 'bottom', horizontal: 'center' })

        } catch (err) {
            console.error('Unable to copy text to clipboard', err);
        }
    }

    const [items, setItems] = useState([]);
    const [HasMore, setHasMore] = useState(false)
    const [Page, setPage] = useState(1)

    const [SnackbarState, setSnackbarState] = useState(false);
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarState(false);
    };
    const [Data, setData] = useState(false)

    const fetchData = async () => {
  
        try {
            const MakeApiClass = new AllApi();
            let res = await MakeApiClass.GetHistoryFunc(Page)
  
            if (res.code == 200) {
            
                if (res.data.data.length > 0 || Data.length > 0) {
                    res.data.data.length > 0 ? setHasMore(true) : setHasMore(false)

                    let DataBeforeSend = res.data.data
                    DataBeforeSend.map(item => {
                     
                        item.PhoneTest = phone('+' + item.phonenumber)
           
                        // item.approvalDate = moment(item.approvalDate, 'jYYYY/jM/jD');

                        // item.approvalDate=item.approvalDate?moment(item.approvalDate, 'jYYYY/jMM/jDD  HH:mm:ss').valueOf():item.approvalDate
                    })
               
                    setPage(Page + 1)
                    if (Data.length > 0) {
                        setData([...Data, ...DataBeforeSend])
                    } else {
                        setData(DataBeforeSend)

                    }

                } else {
                    setData('empty')

                }
            } else {
                setSnackbarState(true)
                setData('empty')
            }



        } catch (error) {
           
        }



        // setData(DataBeforeSend)
        // axios
        //     .get(`https://jsonplaceholder.typicode.com/photos?_page=${Page}&_limit=10`)
        //     .then((res) => {
     
        //         res.data.length > 0 ? setHasMore(true) : setHasMore(false)
        //         Data.length > 0 ?
        //         setData([...items, ...res.data]) :
        //         setData(res.data)
        //         setPage(Page + 1)
        //     });
    };

    const refresh = (setItems) => { fetchData(); };



    useEffect(() => {
        fetchData()
    }, [])
 
  
    return (

        <div className='SaleHistory' >
            <div className='SaleHistoryHeader'>
                <Link to="/Coin?Status=Sale" className="BackIconSaleHistory" >
                    <img src={BackImg} className="RegisterBackIconImg" />

                </Link>
                <div className='BackIconSaleHistoryText'>
                    History
                </div>
            </div>
            <div className='SaleHistoryBody'>
                {
                    Data ?
                        Data == 'empty' ?

                            <div className='HistoryEmpty'>
                                <img src={HistoryEmpty} />
                                <div className='HistoryEmptyText'>
                                    History is empty
                                </div>
                            </div> :
                            Data.length > 0 ?
                                <div id="scrollableDiv" >

                                    <InfiniteScroll
                                        dataLength={Data.length} //This is important field to render  the next data
                                        next={() => fetchData()}
                                        //   onScroll={()=>fetchData(setItems, items,page + 1)}
                                        hasMore={HasMore}
                                        loader={<div className="loadminijoin1"></div>}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                                <b></b>
                                            </p>
                                        }
                                        scrollableTarget="scrollableDiv"
                                        // below props only if you need pull down functionality
                                        refreshFunction={refresh}
                                        pullDownToRefresh
                                        pullDownToRefreshThreshold={1}
                                        pullDownToRefreshContent={
                                            <h3 style={{ textAlign: "center" }}> </h3>
                                        }
                                        releaseToRefreshContent={
                                            <h3 style={{ textAlign: "center" }}> </h3>
                                        }
                                    >
                                        <div className='SaleHistoryItems' >
                                            {

                                                Data.map((item, i) => (
                                                    // <img src={user.url} height="100px" width="200px" key={i} />
                                                    <div className='SaleHistoryItem' key={i}>
                                                        <div className='SaleHistoryItemInner'>
                                                            <div className='SaleHistoryItemTitle'>Status</div>
                                                            <div className='SaleHistoryItemValue'>
                                                                {
                                                                    item.active == true && item.done == false && item.ban == false && item.logout == false ?
                                                                        <>
                                                                            Pending verification <img src={Pending} className='SaleHistoryItemValuePic' />
                                                                        </>



                                                                        : item.done == true ?
                                                                            <>
                                                                                Verified <img src={Checked} className='SaleHistoryItemValuePic' />
                                                                            </>
                                                                            : item.done == false && (item.ban == true || item.logout == true) ?
                                                                                <>Banned or logged out <img src={Banned} className='SaleHistoryItemValuePic' />

                                                                                </>
                                                                                // :  item.done == true && item.logout == true ?
                                                                                //     <>Banned or logged out <img src={Banned} className='SaleHistoryItemValuePic' />
                                                                                //     </>
                                                                                : null




                                                                }


                                                                {/* <img src={Checked} className='SaleHistoryItemValuePic' /> */}
                                                            </div>
                                                        </div>
                                                        <div className='SaleHistoryItemInner'>
                                                            <div className='SaleHistoryItemTitle'>Phone number</div>
                                                            <div className='SaleHistoryItemValue'>+{item.phonenumber}
                                                                < ReactCountryFlag
                                                                    // key={j}
                                                                    countryCode={item.PhoneTest.countryIso2}
                                                                    // countryCode={'CL'}
                                                                    className='FlagPhoneNumber'
                                                                    style={{ "marginLeft": "4px" }}
                                                                    svg
                                                                    title={item.PhoneTest.countryIso2}
                                                                />

                                                                {/* {item.countryName} */}
                                                            </div>
                                                        </div>
                                                        <div className='SaleHistoryItemInner'>
                                                            <div className='SaleHistoryItemTitle'>Received coin </div>
                                                            <div className='SaleHistoryItemValue'>{
                                                              item.done == true  ?
                                                                    item.price?item.price:2000 : 0} <img src={Coin} className='SaleHistoryItemValuePic' /></div>
                                                        </div>
                                                        <div className='SaleHistoryItemInner'>
                                                            <div className='SaleHistoryItemTitle'>Date</div>
                                                            <div className='SaleHistoryItemValue'> {
                                                                // moment(item.approvalTiem.format())
                                                                // moment(item.approvalTiem).format("YYYY/MM/DD HH:mm:ss")
                                                                // moment(item.approvalTiem).format("MMM Do YY HH:mm:ss")
                                                                // moment(item.approvalTiem).calendar()      // Tuesday at 11:01 AM

                                                                // &&
                                                                // moment(item.approvalTiem).calendar( {
                                                                //     sameDay: '[Today] , HH : MM : SS',
                                                                //     nextDay: '[Tomorrow]',
                                                                //     nextWeek: 'dddd',
                                                                //     lastDay: '[Yesterday] , HH : MM',
                                                                //     // lastWeek: '[Last] dddd',
                                                                //     lastWeek: 'DD MMM , YYYY',
                                                                //     sameElse: 'DD MMM , YYYY'
                                                                // })
                                                                item.approvalDate ?
                                                                    moment.from(item.approvalDate, 'fa', 'YYYY/M/D HH:mm')
                                                                        .format('YYYY-M-D HH:mm')


                                                                        
                                                                // moment(item.approvalDate).calendar()
                                                                // item.approvalDate.locale('en').format('jYYYY/jM/jD [is] YYYY/M/D')
                                                                // moment(item.approvalDate, 'jYYYY/jMM/jDD  HH:mm:ss').valueOf()
                                                                // moment(item.approvalDate).calendar({
                                                                //     sameDay: `[Today], HH:mm`,
                                                                //     nextDay: '[Tomorrow]',
                                                                //     nextWeek: 'dddd',
                                                                //     lastDay: '[Yesterday]',
                                                                //     lastWeek: '[Last] dddd',
                                                                //     sameElse: 'DD/MM/YYYY'
                                                                // })
                                                                :
                                                                moment(item.approvalTiem).calendar({
                                                                    sameDay: `[Today], HH:mm`,
                                                                nextDay: '[Tomorrow]',
                                                                nextWeek: 'dddd',
                                                                lastDay: '[Yesterday]',
                                                                lastWeek: '[Last] dddd',
                                                                sameElse: 'DD/MM/YYYY'
                                                                })

                                                            }
                                                            </div>
                                                        </div>
                                                        <div className='SaleHistoryItemInner'>
                                                            <div className='SaleHistoryItemTitle'>Tracking number</div>
                                                            <div className='SaleHistoryItemValueToken' onClick={(e) => copyText(item._id)}>
                                                                {/* {item._id.substr(0,16)} */}

                                                                {
                                                                    item._id.length > 15 ?
                                                                        item._id.substring(0, 15) + '...' :
                                                                        item._id
                                                                }
                                                                <img src={Copy} className='SaleHistoryItemValuePic' /></div>
                                                        </div>
                                                    </div>
                                                ))

                                            }
                                        </div>
                                    </InfiniteScroll>
                                </div>
                                :
                                <div className='HistoryEmpty'>
                                    <img src={HistoryEmpty} />
                                    <div className='HistoryEmptyText'>
                                        History is empty
                                    </div>
                                </div>

                        :
                        <Loading />
                }



            </div>

            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={opens}
                onClose={handleClose}
                // message={` Password changed`}
                key={vertical + horizontal}
                autoHideDuration={2000}
                message={<span id="client-snackbar">
                    {/* <CheckCircleIcon className='CheckIcon'/> */}
                    Tracking number was copied</span>}

            />
            <Snackbar
                open={SnackbarState}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                // message={<span id="client-snackbar"><ErrorOutlineIcon className='ErrorIcon' />Something went wrong! Please try again.</span>}
                message="Something went wrong! Please try again."

            />
        </div>
    )
}

export default SaleHistory