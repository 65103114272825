import React from 'react'
import './Countries.css'
import LanguageIcon from '@mui/icons-material/Language';
import { useSelector, useDispatch } from 'react-redux'
import { SetCountry } from './../app/Home'
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
const Countries = () => {
    const dispatch = useDispatch()
    const Countries = useSelector((state) => state.Home.Countries)
    const PackageData = useSelector((state) => state.Home.PackageData)

    let FilterCountries = localStorage.getItem('FilterCountries')
    useEffect(() => {
        if (FilterCountries) {
            HandleCountry(FilterCountries)
        }
        else {
            HandleCountry("World")
        }
    }, [])

    const HandleCountry = (value) => {
        dispatch(SetCountry(value))
        localStorage.setItem('FilterCountries', value)
    }
  
    return (
        <> <div className='CountriesContainer'>
            {
                // PackageData.length > 1 ?
                   
                        <div className='Countries'>
                            {PackageData.map((item, i) =>
                                <div key={i} className={Countries === item.buyCountries.name ? "CountryItemActive" : 'CountryItem'} onClick={() => { HandleCountry(item.buyCountries.name) }}>
                                    {item.buyCountries.name === "World" ?
                                        <LanguageIcon className='Flag' />
                                        :
                                        <img src={item.buyCountries.flag} className='Flag' />
                                    }
                                    {item.buyCountries.name}
                                </div>
                            )}
                        </div>
                
                    // : null
            }    </div>
        </>



    )
}

export default Countries